// @mui
import { Grid, Card, CardHeader, Box, CardContent, Divider } from '@mui/material'
import { CustomTooltip } from "../../utils/customStyles";
// ----------------------------------------------------------------------

export default function TrialOrgs ({ title, subheader, list }) {

  return (
    <Card>
        <CardHeader 
            title={title} 
            subheader={subheader}
        />

        <CardContent sx={{ maxHeight: "50vh", overflow: "scroll" }}>
            <Grid container spacing={2}>
                {list && list.map((org) => (
                    <Grid item xs={2}>
                        <CustomTooltip title={org.name}>
                            <Box 
                                component="img" 
                                alt={org.name.slice(0,1)} 
                                src={org.s3_logo_url} 
                                sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }} 
                            />
                        </CustomTooltip>
                    </Grid>
                ))}
            </Grid>
        </CardContent>

        <Divider />
    </Card>
  );
}
