import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// @mui
import {
  Stack, 
  Modal, 
  Divider, 
  Typography, 
  IconButton,
  Card,
  CardHeader,
  TextField,
  FormControl,
  Switch,
  FormControlLabel,
  FormLabel
} from '@mui/material/';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
// @mui-icons
import { Close } from '@mui/icons-material';
// APIs
import { updateUserOrgProfile } from '../../api/users';
// Utils
import { toISOLocal, formatDate, calculateExpirationDate, calculateDaysDifference } from '../../utils/formatFunctions';
import { commonModalStyle } from '../../utils/customStyles';
// ----------------------------------------------------------------------

export default function EditUser ({
  openModal,
  handleCloseModal,
  orgProfile,
  snackbarState,
  setSnackbarState
}){
const [errorMessage, setErrorMessage] = useState("")
const [expirationDate, setExpirationDate] = useState(null)
const [firstName, setFirstName] = useState(null)
const [lastName, setLastName] = useState(null)
const [downloadAnalyzed, setDownloadAnalyzed] = useState(false)
const [downloadRaw, setDownloadRaw] = useState(false)
const [downloadMeta, setDownloadMeta] = useState(false)

useEffect(()=> {
    if(orgProfile){

      setFirstName(orgProfile.first_name)
      setLastName(orgProfile.last_name)
      
      if(orgProfile.hasOwnProperty('date_duration')) {
        const date = calculateExpirationDate(orgProfile.date_created, orgProfile.date_duration)
        setExpirationDate(date)
      } 

      const downloadAnalyzed = orgProfile.download_analyzed || false;
      console.log(downloadAnalyzed);
      const downloadRaw = orgProfile.download_raw || false;
      console.log(downloadRaw);
      const downloadMetadata = orgProfile.download_metadata || false;
      console.log(downloadMetadata);

      setDownloadAnalyzed(downloadAnalyzed)
      setDownloadRaw(downloadRaw)
      setDownloadMeta(downloadMetadata) 
    }

}, [orgProfile])

const handleSuccessfulUpdate = () => {
  setSnackbarState()
    // setSnackbarState({ 
    //   ...snackbarState, 
    //   open: true, 
    //   text: "The User Org Profile has been successfully updated!" 
    // });
    // handleCloseModal()
}

const handleUserUpdate = async() => {
  const date = new Date()
  const formattedDate = toISOLocal(date)

  const reformattedExpirationDate = dayjs(expirationDate).format("MM/DD/YYYY")

  const convertedExpirationDate = 
    calculateDaysDifference(orgProfile.date_created.slice(0,10), reformattedExpirationDate)
    

  const updatedData = {
    pkId: orgProfile.pk_id,
    skId: orgProfile.sk_id, 
    dateDuration: convertedExpirationDate, 
    dateUpdated: formattedDate,
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    downloadAnalyzed,
    downloadMeta,
    downloadRaw
  }

  console.log(updatedData, orgProfile)
  const response = await updateUserOrgProfile(updatedData)
  response.success === true ? handleSuccessfulUpdate() : setErrorMessage("Something went wrong!")
}

  if (orgProfile) {
    return (
        <Modal
            open={openModal}
            hideBackdrop={false}
            onClose={handleCloseModal}
        >
            <Card sx={commonModalStyle}>
                <CardHeader 
                    title={"Edit User"} 
                    action={
                    <IconButton onClick={handleCloseModal}>
                        <Close/>
                    </IconButton> 
                    }
                />

                <Divider/>

                <Stack spacing={2} mt={2}>

                {/* <Typography>
                    Date Created: {formatDate(orgProfile.date_created)}
                </Typography>

                <Typography>
                    Date to Expire: {formatDate(calculateExpirationDate(orgProfile.date_created, orgProfile.date_duration))}
                </Typography> */}

                <Typography>
                    Org Id: {orgProfile.organization_id}
                </Typography>

                <Typography>
                    Type: {orgProfile.type}
                </Typography>
          
                <Stack direction="row" spacing={2}>
                  <FormControl required>
                    <FormLabel>Download Analyzed</FormLabel>
                      <FormControlLabel control={<Switch checked={downloadAnalyzed} onChange={(e) => { setDownloadAnalyzed(e.target.checked)}}/>} />
                  </FormControl>

                  <FormControl required>
                    <FormLabel>Download Raw</FormLabel>
                      <FormControlLabel control={<Switch checked={downloadRaw} onChange={(e) => { setDownloadRaw(e.target.checked)}}/>} />
                  </FormControl>

                  <FormControl required>
                    <FormLabel>Download Meta</FormLabel>
                      <FormControlLabel control={<Switch checked={downloadMeta} onChange={(e) => { setDownloadMeta(e.target.checked)}}/>} />
                  </FormControl>
                </Stack>

                <Stack direction={"row"} spacing={1}>

                  <TextField
                    sx={{ width: "50%"}}
                    label="First Name"
                    value={firstName}
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                  />

                  <TextField
                    sx={{ width: "50%"}}
                    label="Last Name"
                    value={lastName}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                </Stack>

                <DatePicker label="Expiration Date" value={dayjs(expirationDate)} onChange={(newValue) => setExpirationDate(dayjs(newValue))} />

                <Typography>{errorMessage}</Typography>

                <LoadingButton
                    variant="contained"
                    onClick={(e)=> handleUserUpdate(e)}
                    sx={{width: "50%", alignSelf: 'center'}}
                >
                    Update User Org Profile
                </LoadingButton>
                </Stack>
            </Card>
        </Modal>
    )
  }
}