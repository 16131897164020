// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, OutlinedInput, FormControl, FormControlLabel, Switch } from '@mui/material';
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

export default function OrgListToolbar({ filterName, onFilterName, expiredOrgs, handleExpirationSwitch }) {
  return (
    <StyledRoot>
      <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Search Orgs..."
      />

      <FormControl>
        <FormControlLabel control={<Switch checked={expiredOrgs} onChange={handleExpirationSwitch}/>} label="Expired Orgs" labelPlacement="start"/>
      </FormControl>
    </StyledRoot>
  );
}
