import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import {
  Table, Stack, Paper, Avatar, Button, Popover, TableRow, Divider,
  MenuItem, Container, TableBody, TableCell, Typography, Grid, List,
  IconButton, TableContainer, TablePagination, Chip, Snackbar, Alert, ListItem, 
} from '@mui/material';
// @mui-icons
import { Cancel, CheckCircle, InfoOutlined, MoreVert, ContentCopy, ReportProblem, WarningOutlined, DataObject, Edit, HorizontalRule, Object } from '@mui/icons-material';
// Sections
import { OrgListHead, OrgListToolbar } from '../sections/orgs';
import { EditOrg } from '../sections/org';
// API Calls
import { listAllOrgDisplays, listAllOrgs } from "../api/orgs";
import { listAllOrgUsers } from "../api/users";
import { findMostRecentSessionByOrgId } from '../api/sessions';
// Utils
import { handleShowJson } from '../utils/jsonFunction';
import { convertToNonMilitaryTime, descendingComparator, formatDate, applySortFilter, getComparator, calculateExpiration } from '../utils/formatFunctions';
import { handleCopyClick } from '../utils/copyFunction';
import { productIconMap } from '../utils/mapFunctions';
import { CustomTooltip } from '../utils/customStyles';
import { validateOrganizationObject } from '../utils/validateFunctions';
// ----------------------------------------------------------------------

const organizationTableHeadLabels = [
  { id: 'organization', label: 'Organization', alignRight: false },
  { id: 'recentSession', label: 'Recent Session', alignRight: false },
  { id: 'access', label: 'Access', alignRight: false },
  { id: 'producst', label: 'Products', alignRight: false },
  { id: 'orgStatus', label: 'Org Status', alignRight: false },
  { id: 'expired', label: 'Expired', alignRight: false },
  { id: 'menu', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

export default function Organizations () {
  const [openMenu, setOpenMenu] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [orgData, setOrgData] = useState(null)

  const [orgDisplays, setOrgDisplays] = useState(null)

  const [orgMenus, setOrgMenus] = useState(null)

  const [users, setUsers] = useState(null)

  const [orgId, setOrgId] = useState(null)

  const [expiredOrgs, setExpiredOrgs] = useState(false)

  const [jsonData, setJsonData] = useState(null)

  const [openEditModal, setOpenEditModal] = useState(false)

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    text: ""
  });

  const { vertical, horizontal, open, text } = snackbarState;

  useEffect(()=> {

    const fetchOrgData = async() => {
        try {
          const data = await listAllOrgs()
          const orgTableArray = data.data
          const orgs = orgTableArray.filter((org)=> org.sk_id !== "menu")
          const menus = orgTableArray.filter((org)=> org.sk_id === "menu")

          const updatedOrgs = await Promise.all(
            orgs.map(async (org) => {
              const res = await findMostRecentSessionByOrgId(org.organization_id);
              return {...org, recent_session: res.data[0] };
            })
          );

          const sortedOrgs = [...updatedOrgs].sort((a, b) => {
            const aDate = a.recent_session? new Date(a.recent_session.date) : null;
            const bDate = b.recent_session? new Date(b.recent_session.date) : null;
        
            if (!aDate &&!bDate) {
              return 0;
            } else if (!aDate) {
              return 1;
            } else if (!bDate) {
              return -1;
            } else {
              return bDate - aDate;
            }
          });

        setOrgMenus(menus)
        setOrgData(sortedOrgs)
      } catch(err) {
        console.log(err)
      }
    }

    const fetchUsers = async() => {
      try {
          const data = await listAllOrgUsers()
          setUsers(data.data)
      } catch(err) {
          console.log(err)
      }
    }

    const fetchOrgDisplays = async() => {
      try {
          const data = await listAllOrgDisplays()
          setOrgDisplays(data.data)
      } catch(err) {
          console.log(err)
      }
    }

    fetchOrgData()
    fetchUsers()
    fetchOrgDisplays()
  }, [snackbarState])

  const editor = localStorage.getItem("user_type") === "editor"

  const handleOpenMenu = (event, row) => {
    setOpenMenu(event.currentTarget);
    setJsonData(row)
    setOrgId(row.organization_id)
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleExpirationSwitch = (e)=> {
    setExpiredOrgs(e.target.checked)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (orgData && users && orgDisplays && orgMenus) {

    const data = orgData.filter((org) => {
      const remainingMembershipTime = calculateExpiration(org.date_created, org.date_duration);
      return expiredOrgs ? remainingMembershipTime.expired : !remainingMembershipTime.expired;
    });

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const filteredOrgs = applySortFilter(data, filterName);

    const isNotFound = !filteredOrgs.length && !!filterName;

    return (
      <Container>
        <Stack direction={"row"} justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              All Organizations
            </Typography>

            <Link to={editor? '/dashboard/createorganization' : null } state={{ orgMenus }}>
              <Button variant="contained">
                Create New Org
              </Button>
            </Link>
          </Stack>

        <OrgListToolbar 
          filterName={filterName} 
          onFilterName={handleFilterByName} 
          expiredOrgs={expiredOrgs}
          handleExpirationSwitch={handleExpirationSwitch}
        />

        <TableContainer p={0}>
          <Table>
            <OrgListHead
              order={order}
              orderBy={orderBy}
              headLabel={organizationTableHeadLabels}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredOrgs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, s3_logo_url, name, notes, organization_id, org_type, admin_count, user_count, date_created, date_duration, token_api, recent_session } = row;
                const mostRecentSession = recent_session
                console.log(mostRecentSession)
                const remainingMembershipTime = calculateExpiration(date_created, date_duration);

                // Getting it with display entries
                const orgDisplay = orgDisplays?.find((org)=> org.pk_id === organization_id)
                const productIds = orgDisplay?.product_list
  
                // Getting it with menu entries 
                const status = row.menu_id
                const products = status !== undefined ? orgMenus.find((menu)=> menu.pk_id === status) : null

                const istokenApiAnArray = token_api !== null

                const {recent_session: _, ...org } = row
                const validationResult = validateOrganizationObject(org);
                const productIcons = status && products && products.product_list? products.product_list : productIds;

                const productIconsList = productIcons ? productIcons.map((product, index) => (
                  <CustomTooltip title={<Typography variant='caption'>{product.display_name ? product.display_name : product.product_id}</Typography>}>
                    {productIconMap[product.product_id]}
                  </CustomTooltip>
                ))
                : 
                  <Typography>
                    No Matching Menu
                  </Typography>
                ;

                return (
                  <TableRow key={id} tabIndex={-1}>
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Link 
                          to={editor ? `/dashboard/org/${name}` : '/dashboard/orgs'}
                          style={{textDecoration: "none", color: "black"}}
                          state= {{ 
                            orgId: organization_id,
                            orgMenus
                          }}
                        >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <CustomTooltip 
                              title={
                                <Stack>
                                  <Typography variant='caption'>User Count: {user_count}</Typography>
                                  <Typography variant='caption'>Admin Count: {admin_count}</Typography>
                                </Stack>
                              } 
                            >
                              <Avatar 
                                alt={name} 
                                src={s3_logo_url} 
                              />
                            </CustomTooltip>
                            <Stack>

                              <Typography noWrap fontWeight={700}>
                                {name}
                              </Typography>

                              <Typography variant='caption'>Created on {formatDate(date_created)}</Typography>
                            </Stack>
                          </Stack>
                        </Link>

                        <CustomTooltip 
                          title={
                            <Typography variant='caption'>
                              {notes}
                            </Typography> 
                          }
                        >
                          <InfoOutlined/>
                        </CustomTooltip>

                        { validationResult.valid === false &&
                          <CustomTooltip 
                            title={
                              <Typography variant='caption'>
                                {validationResult.message}
                              </Typography>
                            }
                          >
                            <WarningOutlined sx={{color: "error.light"}}/>
                          </CustomTooltip>
                        }
                      </Stack>
                    </TableCell>

                    <TableCell align="left">{mostRecentSession ? 
                      <CustomTooltip 
                        title={
                          <List>

                            <ListItem>
                              <Typography textAlign={"center"} variant="caption">Time: {convertToNonMilitaryTime(mostRecentSession.date.slice(11,16))}</Typography>
                            </ListItem>

                            <Divider/>
                            
                            <ListItem>
                              <Typography textAlign={"center"} variant="caption">Performer: {mostRecentSession.performer.performer_name}</Typography>
                            </ListItem>

                            <Divider/>

                            <ListItem>
                              <Typography textAlign={"center"} variant="caption">Activity: {mostRecentSession.activity_list[0].activity.activity_name}</Typography>
                            </ListItem>

                            <Divider/>

                            <ListItem dense>
                              <Typography textAlign={"center"} variant='caption'> Copy Most Recent Session ID</Typography>
                              <IconButton onClick={() => handleCopyClick(mostRecentSession.session_id)}>
                                  <ContentCopy />
                              </IconButton>
                            </ListItem>

                            <Divider/>

                            <ListItem dense>
                              <Typography textAlign={"center"} variant='caption'>View Session JSON</Typography>
                              <IconButton onClick={() => handleShowJson(mostRecentSession)}>
                                <DataObject/>
                              </IconButton>
                            </ListItem>

                          </List>
                        }
                      >
                        <Typography>Session #{mostRecentSession.session_number}</Typography>
                        <Typography>{formatDate(mostRecentSession.date)}</Typography>

                      </CustomTooltip>
                      : 
                        "No Sessions Yet"
                      }
                    </TableCell>

                    <TableCell align="left">
                      <Stack spacing={1}>
                        {row?.download_analyzed === false ? null : 
                          <Chip
                            icon={<HorizontalRule color='warning' sx={{transform: 'rotate(90deg)'}}/>}
                            variant='outlined'
                            sx={{maxWidth: "fit-content"}}
                            label={"Download Analyzed"}
                          /> 
                        }
                        {row?.download_raw === false? null : 
                          <Chip
                            sx={{maxWidth: "fit-content"}}
                            icon={<HorizontalRule color='error' sx={{transform: 'rotate(90deg)'}}/>}
                            variant='outlined'
                            label={"Download Raw"}
                          /> 
                        }
                        {row?.download_metadata === false? null : 
                          <Chip
                            sx={{maxWidth: "fit-content"}}
                            icon={<HorizontalRule color='info' sx={{transform: 'rotate(90deg)'}}/>}
                            variant='outlined'
                            label={"Download Meta"}
                          /> 
                        }

                        {row?.menu_id && 
                          <Chip
                            sx={{maxWidth: "fit-content"}}
                            icon={<HorizontalRule color='success' sx={{transform: 'rotate(90deg)'}}/>}
                            variant='outlined'
                            label={row.menu_id}
                          /> 
                        }

                        {istokenApiAnArray && token_api.length > 0 && 
                          <Chip
                            sx={{maxWidth: "fit-content"}}
                            icon={<HorizontalRule color='primary' sx={{transform: 'rotate(90deg)'}}/>}
                            variant='outlined'
                            label={"API Access"}
                          /> 
                        }
                      </Stack>
                    </TableCell>

                    <TableCell align='left'>
                      <Stack>
                        {productIconsList}
                      </Stack>
                    </TableCell>

                    <TableCell align="left">
                      {org_type &&   
                        org_type === "trial" ?                           
                        <Chip
                          color={ "primary"}
                          variant='outlined'
                          label={<Typography color={"black"}>{org_type}</Typography>}
                        /> 
                        :
                        <Typography>{org_type}</Typography>
                      }
                    </TableCell>

                    <TableCell align="left">
                      <CustomTooltip
                        title={
                          <Typography variant='caption'>{remainingMembershipTime.differenceInDays <= 0 ? "Expired on": "Expires on"}: {formatDate(remainingMembershipTime.expirationDate)}</Typography>
                        }>
                  
                      <Stack direction="row" spacing={1}>
                          {remainingMembershipTime.differenceInDays < 0  ? <Cancel sx={{color: "error.main"}}/> : remainingMembershipTime.differenceInDays  < 30 ? <ReportProblem sx={{color: "warning.main"}}/> : <CheckCircle sx={{color: "success.dark"}}/>}
                          <Typography>
                          {remainingMembershipTime.differenceInDays  < 0  ? `Expired` : remainingMembershipTime.differenceInDays  < 10 ? `${remainingMembershipTime.differenceInDays } days left` : `${remainingMembershipTime.differenceInDays } days`}
                          </Typography>
                      </Stack>
                  
                      </CustomTooltip>

                    </TableCell>
                    
                    <TableCell align="left">             
                      <IconButton size="large" color="inherit" onClick={(e)=>handleOpenMenu(e, org)}>
                        <MoreVert/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
          component="div"
          count={filteredOrgs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      
      { jsonData && 
        <EditOrg 
          openModal={openEditModal} 
          handleCloseModal={()=> setOpenEditModal(false)} 
          orgId={orgId} 
          orgMenus={orgMenus}
          orgData={jsonData}
          snackbarState={snackbarState} 
          setSnackbarState={setSnackbarState}
        />
      }
        
      {/* Pull Out */}
      <Popover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem>
          <Typography>View JSON</Typography>
          <IconButton onClick={() => handleShowJson(jsonData)}>
            <DataObject/>
          </IconButton>
        </MenuItem>

        <MenuItem>
          <Typography>Edit Org </Typography>
          <IconButton onClick={()=> setOpenEditModal(true)}>
            <Edit/>
          </IconButton>
        </MenuItem>
      </Popover>

      <Snackbar 
        open={open} 
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {text}
        </Alert>
      </Snackbar>
      </Container>
    );
  }

  return (
    <Container>
      <Stack direction={"row"} justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          All Organizations
        </Typography>

        <Link to={editor? '/dashboard/createorganization' : null } state={{ orgMenus }}>
          <Button variant="contained">
            Create New Org
          </Button>
        </Link>
      </Stack>
    </Container>
  )
}