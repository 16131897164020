import { Navigate, useRoutes } from 'react-router-dom';
// Components
import DashboardLayout from './layout/DashboardLayout';
import Organizations from './pages/Organizations';
import AuthLayout from './layout/AuthLayout';
import Statistics from './pages/Statistics';
import CreateOrg from './pages/CreateOrg';
import Sessions from './pages/Sessions';
import Session from './pages/Session';
import Login from './pages/Login';
import Users from './pages/Users';
import User from './pages/User';
import Org from './pages/Org'
// ----------------------------------------------------------------------

const PrivateRoute = ({ element, isAuthenticated, ...rest }) => {
  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default function Router() {
  const authStatus = localStorage.getItem("auth")
  const isAuthenticated = authStatus; 
  
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <PrivateRoute element={<DashboardLayout />} isAuthenticated={isAuthenticated} />, 
      children: [
        { element: <Navigate to="/dashboard/stats" />, index: true },
        { path: 'stats', element: <Statistics/> },
        { path: 'orgs', element: <Organizations/> },
        { path: 'org/:orgname', element: <Org/> },
        { path: 'users', element: <Users/> },
        { path: 'user/:username', element: <User/> },
        { path: 'sessions', element: <Sessions/> },
        { path: 'session/:session', element: <Session/> },
        { path: 'createorganization', element: <CreateOrg/> },
      ],
    },
    {
      path: '/',
      element: <AuthLayout/>,
      children: [
        { element: <Navigate to="/login" />, index: "true" },
        { path: 'login', element: <Login/> }
      ]
    }
  ]);

  return routes;
}
