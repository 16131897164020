import React, { useState } from 'react';
import dayjs from 'dayjs';
// @mui
import {
  Stack, Modal, Divider, Typography, IconButton, 
  Button, Card, CardHeader,
} from '@mui/material/';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// @mui-icons
import { Close } from '@mui/icons-material';
// Apis
import { addOrganizationApiToken } from '../../api/orgs';
// Utils
import { toISOLocal, calculateExpirationDate, formatDate, calculateDaysDifference} from '../../utils/formatFunctions';
// Utils
import { commonModalStyle } from '../../utils/customStyles';
// ----------------------------------------------------------------------

export default function CreateOrgApiToken ({
  orgName,
  orgId,
  openModal,
  handleCloseModal
}){

 const [loadingStatus, setLoadingStatus] = useState(false)
 const [errorMessage, setErrorMessage] = useState("")
 const [dateDuration, setDateDuration] = useState(formatDate(calculateExpirationDate(toISOLocal(new Date()).slice(0,10), 365)))

  const handleCreateOrgApiToken = async() => {
    const date = new Date()
    const formattedDate = toISOLocal(date)

    const reformattedExpirationDate = dayjs(dateDuration).format("MM/DD/YYYY")

    const convertedExpirationDate = 
      calculateDaysDifference(formattedDate.slice(0,10), reformattedExpirationDate)


    const apiToken = {
      expired: false,
      date_created: formattedDate,
      date_duration: convertedExpirationDate,
    }
    console.log(apiToken)
    const response = await addOrganizationApiToken(orgName, orgId, apiToken)
    response.success ? handleCloseModal() : setErrorMessage('Something went wrong!')
  }

  return (
    <Modal
      open={openModal}
      hideBackdrop={false}
      onClose={handleCloseModal}
    >
      <Card sx={commonModalStyle}>
          <CardHeader 
              title={"Create API Token"} 
              action={
                  <IconButton onClick={handleCloseModal}>
                      <Close/>
                  </IconButton>
              }
          />

          <Divider/>

          <Stack spacing={2} mt={2}>
              <DatePicker 
                label="Expiration Date" 
                value={dayjs(dateDuration)} 
                onChange={(newValue) =>   setDateDuration(dayjs(newValue))}
              />

              <Typography>{errorMessage}</Typography>

              <Button
                  variant="contained"
                  onClick={(e)=> handleCreateOrgApiToken(e)}
                  sx={{width: "50%", alignSelf: 'center'}}
              >
                  Create API Token
              </Button>
          </Stack>
      </Card>
    </Modal>
  )
}