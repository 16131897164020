import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import ThemeProvider from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import "./index.css"
import './fonts/nasalization/nasalization-rg.ttf';
import './fonts/greycliff/GreycliffCF-Regular.ttf';
import './fonts/greycliff/GreycliffCF-Bold.ttf';
import './fonts/greycliff/GreycliffCF-DemiBold.ttf';
import './fonts/greycliff/GreycliffCF-Medium.ttf';

function App() {

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
