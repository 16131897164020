import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { TableCell, TableRow, Typography, Table, TableContainer, TableBody, Chip, TablePagination, Checkbox } from '@mui/material';
// Components
import { OrgListHead } from '../orgs';
// Utils
import { formatDate } from '../../utils/formatFunctions';
// ----------------------------------------------------------------------
const tableHead = [
  { id: 'session', label: 'Session', alignRight: false },
  { id: 'activity', label: 'Activity', alignRight: false },
  { id: 'time', label: 'Total Time', alignRight: false },
  { id: 'reps', label: 'Reps', alignRight: false },
];

export default function UserSessionHistory ({ list, ...other }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if(list.length === 0) return ( <Typography>There are no current sessions</Typography>)
  
  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Table>
        <OrgListHead headLabel={tableHead} hasCheckbox={true}/>
        <TableBody>
          {list.length > 0 && 
            list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <UserSession key={index} info={row} />
            )) 
          }
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]} 
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

// ----------------------------------------------------------------------
function UserSession ({ info }) {
  const { activity_list, date, session_number, session_metrics, session_id} = info
  console.log(info)
  return (
    <TableRow tabIndex={-1}>
      <TableCell padding="checkbox">
        <Checkbox/>
      </TableCell>
      <TableCell component="th" scope="row">
        <Link 
          to={`/dashboard/session/${session_number}`}  
          style={{textDecoration: "none", color: "black"}}
          state= {{ 
            sessionId: session_id
          }}
        >
        <Typography fontWeight={700}>Session #{session_number}</Typography>
      </Link>
        <Typography>{formatDate(date.slice(0,10))}</Typography>
      </TableCell>

      <TableCell>
        <Chip
          label={activity_list[0].activity.display_name}
          variant="outlined"
        />
      </TableCell>

      <TableCell>
          {session_metrics.duration}s
      </TableCell>

      <TableCell>
          {session_metrics.reps}
      </TableCell>
    </TableRow>
  );
}
