// Authenticate User
export const authenticateUser = async (userData) => {
    try {
      const response = await fetch(
        `/data/authenticateuser`, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log("Authenticated:", data)
      return data
    } catch (error) {
      console.error('Error fetching user credentials:', error);
    }
  }

