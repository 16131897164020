import  React, { useEffect, useState } from "react";
// @mui
import { Grid, Typography, Container, Box, Stack } from '@mui/material'
import { LoadingButton } from "@mui/lab";
// Sections
import { StatsCard, StatsList, TrialOrgs, ActivityGraph } from "../sections/statistics"
// API Calls
import { listAllOrgs } from "../api/orgs";
import { listAllOrgUsers } from "../api/users";
import { listAllOrgSessions } from "../api/sessions";
// Utils
import { formatDate, calculateExpiration} from "../utils/formatFunctions";
import { getActiveOrgs, getTrialOrgs, getOrgsExpiringInAMonthAndSortedByDaysLeft } from "../utils/orgFunctions";
import { getUsersJoinedThisWeek, sortOrgProfilesByExpirationDate } from "../utils/userFunctions";
import { createExpiringOrgs, createNewUserList, createExpiringUsers, processActivityData, calculateSessionData, prepareChartData } from "../utils/statsFunctions";
// ----------------------------------------------------------------------

export default function Statistics () {
  const [orgData, setOrgData] = useState(null)

  const [users, setUsers] = useState(null)

  const [orgMenus, setOrgMenus] = useState([])

  const [sessionData, setSessionData] = useState(null)

  const [cumulativeActivityRepData, setCumulativeActivityRepData] = useState(null)

  const [cumulativeActivityDurationData, setCumulativeActivityDurationData] = useState(null)

  const [cumulativeNewUsers, setCumulativeNewUsers] = useState(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgDataResp = await listAllOrgs();
        const usersResp = await listAllOrgUsers();
  
        const [orgData, menus] = orgDataResp.data.reduce(
            ([data, menus], org) => {
                if (org.sk_id === "menu") {
                menus.push(org);
                } else {
                data.push(org);
                }
                return [data, menus];
            },
            [[], []]
        );

        setOrgMenus(menus)
        setOrgData(orgData)
  
        const users = usersResp.data;

        const filteredUsers = users.filter(
          (user) => !user.organization_id && !user.email.includes("nextiles")
        );

        const userSums = {};
        let cumulativeSum = 0;
    
        const sortedUsers = filteredUsers.sort((a, b) => {
            return new Date(a.date_created) - new Date(b.date_created);
        });
      
        sortedUsers.forEach((user) => {
            const date = user.date_created.slice(0, 10);
            cumulativeSum += 1; 
            userSums[date] = cumulativeSum; 
        });

        setCumulativeNewUsers(userSums)

        const userOrgs = users.filter((user) => user.organization_id);
  
        const sortedUserOrgProfiles = userOrgs.sort(
          (a, b) => a.user_id.localeCompare(b.user_id)
        );

        const orgProfiles = sortedUserOrgProfiles.map((userOrg)=> {
            if (userOrg.hasOwnProperty('date_duration')){
                const remainingMembershipTime = calculateExpiration(userOrg.date_created, userOrg.date_duration)
                const daysRemaining = remainingMembershipTime.differenceInDays
                const expiring_this_month = 0 < daysRemaining && daysRemaining <= 30;
                const expiration_date = remainingMembershipTime.expirationDate
                return {...userOrg, expiring_this_month, expiration_date }
            }
            return userOrg
        })

        const sortedUserProfiles = filteredUsers.sort(
          (a, b) => a.user_id.localeCompare(b.user_id)
        );
  
        const matchedUserProfiles = sortedUserProfiles.map((userProfile) => {
          const org_profiles = orgProfiles.filter(
            (orgProfile) => orgProfile.user_id === userProfile.user_id
          );
  
          return { ...userProfile, org_profiles };
        });
  
        setUsers(matchedUserProfiles);
      } catch (err) {
        console.log(err);
      }
    };
  
    fetchData();
  }, []);

  const fetchSessionData = async () => {
    setLoading(true)

    const response = await listAllOrgSessions()
    const orgSessions = response.data
    const sessionData = calculateSessionData(orgSessions)
    const activityData = processActivityData(sessionData.activityData);
    const activtyChartData = prepareChartData(activityData, "reps");
    const activtyDurationData = prepareChartData(activityData, "duration");

    setSessionData(sessionData)
    setCumulativeActivityRepData(activtyChartData)
    setCumulativeActivityDurationData(activtyDurationData)
    setLoading(false)
  }

  if ( orgData && users ) {
    const trialOrgs = getTrialOrgs(orgData)
    const activeOrgs = getActiveOrgs(orgData)
    const sortedOrgsByDaysLeft = getOrgsExpiringInAMonthAndSortedByDaysLeft(orgData)
    const usersJoinedThisWeek = getUsersJoinedThisWeek(users)
    const sortedOrgProfiles = sortOrgProfilesByExpirationDate(users)
    const currentDate = new Date().toISOString();
    const expiringOrgs = createExpiringOrgs(sortedOrgsByDaysLeft, orgMenus)
    const newUsers = createNewUserList(usersJoinedThisWeek)
    const expiringUsers = createExpiringUsers(sortedOrgProfiles)

    return (
        <Container ml={0}>
   
            <Typography variant="h4" gutterBottom>
                Statistics
            </Typography>

            <Grid container spacing={2} rowGap={3}>

                <Grid item xs={12} sm={12} md={3}>

                        <Stack spacing={1} direction={{ xs: "row", md: "column" }}>
                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Typography variant="h6">Active Orgs:</Typography>
                                <Box bgcolor={"primary.lighter"} p={.5}>
                                    <Typography variant="h6" textAlign={"center"}>{activeOrgs.length}</Typography>
                                </Box>
                            </Stack>

                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Typography variant="h6">Expired Orgs:</Typography>
                                <Box bgcolor={"primary.lighter"} p={.5}>
                                    <Typography variant="h6" textAlign={"center"}>{orgData.length - activeOrgs.length}</Typography>
                                </Box>
                            </Stack>

                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Typography variant="h6">Trial Orgs:</Typography>
                                <Box bgcolor={"primary.lighter"} p={.5}>
                                    <Typography variant="h6" textAlign={"center"}>{trialOrgs.length}</Typography>
                                </Box>
                            </Stack>

                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Typography variant="h6">Member Orgs:</Typography>
                                <Box bgcolor={"primary.lighter"} p={.5}>
                                    <Typography variant="h6" textAlign={"center"}>{orgData.length - trialOrgs.length}</Typography>
                                </Box>
                            </Stack>
                        </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={2.25}>
                    <StatsCard 
                        title="Total Users" 
                        total={users.length} 
                        color="secondary" 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2.25}>
                    <StatsCard 
                        title="Total Orgs" 
                        total={orgData.length} 
                        color="info" 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2.25}>
                    <StatsCard 
                        title="New Users This Week"
                        total={usersJoinedThisWeek.length} 
                        color="success" 
                    /> 
                </Grid>

                <Grid item xs={12} sm={6} md={2.25}>
                    <StatsCard 
                        title="Total Sessions Done"
                        total={sessionData ? sessionData.totalSessions :  "TBD"} 
                        color="error"
                    /> 
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">Org Stats</Typography>
                </Grid>

                <Grid item sm={12} md={6}>
                    <TrialOrgs
                        title={`${trialOrgs.length} Trial Orgs`}
                        subheader={`As of ${formatDate(currentDate)}`}
                        list={trialOrgs}
                    />
                </Grid>

                <Grid item sm={12} md={6}>
                    <StatsList
                        title="Orgs Expiring"
                        subheader="In 30 Days"
                        list={expiringOrgs}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">User Stats</Typography>
                </Grid>

                <Grid item sm={12} md={6}>
                    <StatsList
                        title={`${usersJoinedThisWeek.length} Users Joined`}
                        subheader="This Week"
                        list={newUsers}
                    />
                </Grid>

                <Grid item sm={12} md={6}>
                    <StatsList
                        title={`${sortedOrgProfiles.length} Users Expiring`}
                        subheader="This Month"
                        list={expiringUsers}
                    />
                </Grid>

                <Grid item sm={12}>
                    {cumulativeNewUsers &&
                        <ActivityGraph
                            title="New User Growth"
                            subheader={`Since 2023`}
                            chartLabels={Object.keys(cumulativeNewUsers)}
                            chartData={[
                                {
                                    name: "New Users",
                                    data: Object.values(cumulativeNewUsers)
                                }
                            ]}
                        /> 
                    }
                </Grid>

                <Grid item xs={12}>  
                    <Typography variant="h5">Session Stats</Typography>
                    {!sessionData && 
                        <LoadingButton onClick={fetchSessionData} loading={loading}>   
                            Load Session Data
                        </LoadingButton>
                    }
                </Grid>

                <Grid item sm={12}>
                    {sessionData && 
                        <ActivityGraph
                            title="Session Data"
                            subheader={`since 2023`}
                            chartLabels={Object.keys(sessionData.summedSessions)}
                            chartData={[
                                {
                                    name: 'Sessions',
                                    fill: 'solid',
                                    color: '#ccccff',
                                    data: Object.values(sessionData.summedSessions)
                                },
                                {
                                    name: "Reps",
                                    fill: 'solid',
                                    color: '#ADD8E6ff',
                                    data: Object.values(sessionData.summedReps)
                                }
                            ]}
                        /> 
                    }
                </Grid>

                <Grid item sm={12}>
                    {cumulativeActivityRepData &&
                        <ActivityGraph
                            title="Activity Reps"
                            subheader={`over 2024`}
                            chartLabels={cumulativeActivityRepData.chartLabels}
                            chartData={cumulativeActivityRepData.chartData}
                        /> 
                    }
                </Grid>

                <Grid item sm={12}>
                    {cumulativeActivityDurationData &&
                        <ActivityGraph
                            title="Activity Duration"
                            subheader={`over 2024`}
                            chartLabels={cumulativeActivityDurationData.chartLabels}
                            chartData={cumulativeActivityDurationData.chartData}
                        /> 
                    }
                </Grid>
            </Grid>
        </Container>
    );
  }
}