export const getUsersJoinedThisWeek = (users) => {
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const usersJoinedThisWeek = users.filter((user) => {
    const dateJoined = new Date(user.date_created);
    return dateJoined > oneWeekAgo && dateJoined <= new Date();
  });

  const sortedUsers = [...usersJoinedThisWeek].sort((a, b) => {
    const aDate = new Date(a.date_created);
    const bDate = new Date(b.date_created);

    return bDate - aDate;
  });

  return sortedUsers;
};
 export function sortOrgProfilesByExpirationDate(users) {

    const orgProfilesExpiringSoon = users.flatMap(({ org_profiles }) =>
    org_profiles.filter(org => org.expiring_this_month)
  );

    const sortedOrgProfiles = orgProfilesExpiringSoon.sort((a, b) => {
      const aDate = new Date(a.expiration_date);
      const bDate = new Date(b.expiration_date);
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
  
      return aDate === bDate? (aName < bName? -1 : aName > bName? 1 : 0) : aDate - bDate;
    });
  
    return sortedOrgProfiles;
  }
  