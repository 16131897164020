import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// @mui
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack, 
  Modal, 
  Divider, 
  Typography, 
  IconButton,
  Card,
  CardHeader,
  Switch,
} from '@mui/material/';
// @mui-icons
import { Close } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Apis
import { editOrganizationApiToken } from '../../api/orgs';
// Utils
import { calculateExpirationDate, formatDate, calculateDaysDifference } from '../../utils/formatFunctions';
import { toISOLocal } from '../../utils/formatFunctions';
import { commonModalStyle } from '../../utils/customStyles';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function EditApiToken ({
  orgId,
  openModal,
  currentToken,
  handleCloseModal
}){
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [tokenData, setTokenData] = useState(null)

  useEffect(()=> {
    if (currentToken) {
      setTokenData({
        dateDuration:  formatDate(calculateExpirationDate(currentToken.dateCreated, currentToken.dateDuration)),
        dateCreated: currentToken.dateCreated,
        expired: currentToken.expired,
        token: currentToken.token,
        id: currentToken.id
      })
    }
  },[currentToken])


  const handleEditOrgApiToken = async() => {
    const { dateDuration, expired, token, dateCreated, id } = tokenData

    setLoadingStatus(true)

    const reformattedExpirationDate = dayjs(dateDuration).format("MM/DD/YYYY")

    const convertedExpirationDate = 
      calculateDaysDifference(dateCreated.slice(0,10), reformattedExpirationDate)

    const apiToken = {
      id,
      expired,
      date_duration: convertedExpirationDate,
      date_created: dateCreated,
      token
    }

    console.log(apiToken)
    const response = await editOrganizationApiToken(orgId, apiToken)
    response.success ? handleCloseModal() : setErrorMessage('Something went wrong!')
    setLoadingStatus(false)
  }

  if(tokenData){
    const { dateDuration, expired, token, dateCreated, id } = tokenData

    return (
      <Modal
        open={openModal}
        hideBackdrop={false}
        onClose={handleCloseModal}
      >
          <Card sx={commonModalStyle}>
              <CardHeader 
                  title={"Edit Token"} 
                  action={
                      <IconButton onClick={handleCloseModal}>
                          <Close/>
                      </IconButton>
                  }
              />
  
              <Divider/>
  
              <Stack spacing={2} mt={2}>
  
                <Typography>
                  Date Created: {formatDate(dateCreated)}
                </Typography>
  
                <Typography>
                  Token: {token}
                </Typography>
  
                <DatePicker 
                  label="Expiration Date" 
                  value={dayjs(dateDuration)} 
                  onChange={newValue => setTokenData(prevData => ({...prevData, dateDuration: dayjs(newValue) }))}
                />
  
                <FormControl>
                  <FormLabel>Expired</FormLabel>
                    <FormControlLabel control={
                        <Switch
                          checked={expired}
                          onChange={(e) => {
                            setTokenData({ ...tokenData, expired: e.target.checked });
                          }}
                        />
                      } 
                    />
                </FormControl>
  
                <Typography>{errorMessage}</Typography>
  
                <LoadingButton
                  variant="contained"
                  loading={loadingStatus}
                  onClick={(e)=> handleEditOrgApiToken(e)}
                  sx={{width: "50%", alignSelf: 'center'}}
                >
                  Update API Token
                </LoadingButton>
              </Stack>
          </Card>
      </Modal>
    )
  }
}