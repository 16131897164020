// List all org sessions
export const listAllOrgSessions = async () => {
    try {
      const response = await fetch(`/data/sessions`);
      const data = await response.json();
      console.log("All org sessions:", data)
      return data
    } catch (error) {
      console.error('Error fetching org sessions:', error);
    }
}

// Find Session by Id
export const findSessionById = async (sessionId) => {
    try {
      const response = await fetch(`/data/session/${sessionId}`);
      const data = await response.json();
      console.log("Session:", data)
      return data
    } catch (error) {
      console.error('Error fetching session:', error);
    }
}

// Find All Sessions by OrgId
export const findSessionByOrgId = async (orgId) => {
  try {
    const response = await fetch(`/data/orgsessions/${orgId}`);
    const data = await response.json();
    console.log("Session:", data)
    return data
  } catch (error) {
    console.error('Error fetching session:', error);
  }
}

// Find Most recent session by OrgId
export const findMostRecentSessionByOrgId = async (orgId) => {
  try {
    const response = await fetch(`/data/recentorgsession/${orgId}`);
    const data = await response.json();
    // console.log("Session:", data)
    return data
  } catch (error) {
    console.error('Error fetching session:', error);
  }
}

// Fetch All User Session in an Org
export const fetchAllUserSessionsInOrg = async (userId, orgId) => {
  try {
    const response = await fetch(`/data/usersessions/${userId}/${orgId}`);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching user sessions:', error);
  }
};

// Fetch All User Session in an Org
export const fetchSessionsByDate = async (date) => {
  try {
    const response = await fetch(`/data/sessionsbydate/${date}`);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching user sessions:', error);
  }
};
