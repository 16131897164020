import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { 
    Grid, Container, Typography, Stack, Divider, Box,
    Avatar, IconButton, Card, Tabs, Tab, Modal, CardHeader,
    Snackbar, Alert, Button
} from '@mui/material';
import { ContentCopy, AssignmentIndOutlined, Close, EditOutlined, DataObject } from "@mui/icons-material";
// sections
import { UserDeviceList, UserSessionHistory } from "../sections/user";
// API Calls
import { findUserById, getUserDDBEntry, findUserOrgsById } from "../api/users";
import { findOrgById } from "../api/orgs";
import { fetchAllUserSessionsInOrg } from "../api/sessions";
// Components
import { EditMetadata } from "../sections/org";
import { EditUser } from "../sections/users";
// Utils
import { CustomTooltip, commonModalStyle } from "../utils/customStyles";
import { handleCopyClick } from "../utils/copyFunction";
import { formatDate } from "../utils/formatFunctions";
import { handleShowJson } from "../utils/jsonFunction";
import { calculateExpiration } from "../utils/formatFunctions";
// ----------------------------------------------------------------------

export default function User () {
  const [userOrgData, setUserOrgData] = useState(null)

  const [orgDataList, setOrgDataList] = useState([]);

  const [selectedOrgId, setSelectedOrgId] = useState(null);

  const [sessionHistory, setSessionHistory] = useState([])

  const [openMetadataModal, setOpenMetadataModal] = useState(null)

  const [viewDeviceList, setViewDeviceList] = useState(false)

  const [userData, setUserData] = useState(null)

  const [viewEditModal, setViewEditModal] = useState(false)

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    text: ""
  });

  const location = useLocation();
  
  const { vertical, horizontal, open, text } = snackbarState;

  const userId = location.state.userId || location.state.state.userId

    useEffect(()=> {
        
        const fetchUserData = async ()=> {
            const userData = await getUserDDBEntry(userId)
            setUserData(userData.data[0])
        }

        fetchUserData()

    }, [openMetadataModal])

    useEffect(() => {
        const fetchData = async () => {
            if (selectedOrgId) {
                setSessionHistory(null);
                const userOrgData = await findUserById(userId, selectedOrgId);
                const sessionHistory = await fetchAllUserSessionsInOrg(userId, selectedOrgId);

                setUserOrgData(userOrgData.data[0]);
                setSessionHistory(sessionHistory.data);
            }
        };

        if (selectedOrgId) {
            fetchData();
        }
    }, [selectedOrgId, userId]);

    useEffect(() => {
        const fetchOrgDataList = async () => {
            const res = await findUserOrgsById(userId)
            const org_ids = res.data.org_ids


            const orgProfiles = await Promise.all(
                org_ids.map(async (org) =>{
                    const res = await findUserById(userId, org)
                    console.log(res)
                    return res.data[0]
            }))

            console.log(orgProfiles)

            const orgDataList = await Promise.all(
                orgProfiles.map(async (org) => {
                    const orgData = await findOrgById(org.organization_id);
                    const remainingMembershipTime = calculateExpiration(
                        org.date_created,
                        org.date_duration
                    );
                    return {
                        s3: orgData.data[0].s3_logo_url,
                        orgId: org.organization_id,
                        name: orgData.data[0].name,
                        timeLeft: remainingMembershipTime.expirationDate,
                    };
                })
            );

            setOrgDataList(orgDataList);
            setSelectedOrgId(org_ids[0]);
        };

        if (!selectedOrgId) {
            fetchOrgDataList();
        }
    }, [ selectedOrgId]);

    const handleAvatarClick = (event, newValue) => {
        setSelectedOrgId(newValue);
    };

    const handleCloseDeviceList = () => {
        setViewDeviceList(false)
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
        <div
            role="tabpanel"
            hidden={value !== selectedOrgId}
            id={selectedOrgId}
        >
            {value === selectedOrgId && (
            <Box p={2}>
                {children}
            </Box> 
            )}
        </div>
        );
    }

    const handleSnackbarState = () => {
        setSnackbarState({ 
            ...snackbarState, 
            open: true, 
            text: "The User Org Profile has been successfully updated!" 
        });
        setSelectedOrgId(null)
        handleCloseModal()
    }

    const handleCloseSnackbar = () => {
        setSnackbarState({ 
            ...snackbarState, 
            open: false, 
        });
    }

    const handleCloseModal = () => {
        setViewEditModal(false)
    }

    if(userOrgData && userData){

        const downloadAnalyzed = userOrgData.download_analyzed === undefined ? "Not Found" : userOrgData.download_analyzed === false ? "false" : "true" ;

        const downloadRaw = userOrgData.download_raw === undefined ? "Not Found" : userOrgData.download_raw === false ? "false" : "true" ;

        const downloadMetadata = userOrgData.download_metadata === undefined ? "Not Found" : userOrgData.download_metadata === false ? "false" : "true" ;

        return (
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack 
                            direction="row" 
                            alignItems={"center"} 
                            mb={2}
                        >
                            <Typography variant="h3">{userData.name}</Typography>
                            <IconButton onClick={() => handleShowJson(userData)}>
                                <DataObject/>
                            </IconButton>
                            <IconButton onClick={() => setOpenMetadataModal(true)}>
                                <EditOutlined/>
                            </IconButton>
                        </Stack>
                        <Stack         
                            direction="row"
                            alignContent={"center"}
                            alignItems="center"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={1}
                            mb={2}
                        >
                            <Stack direction="row" spacing={1}>
                                <CustomTooltip 
                                    title={
                                        <>
                                            <Typography variant="caption">
                                                Copy User ID
                                            </Typography>
                                            <IconButton onClick={()=> handleCopyClick(userData.user_id)}>
                                                <ContentCopy/>
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <AssignmentIndOutlined />
                                </CustomTooltip>
                                <Typography fontWeight={700}>User ID</Typography>
                            </Stack>
    
                            <Stack direction="row" spacing={1}>
                                <CustomTooltip 
                                    title={
                                        <Stack>
                                            {/* {orgProfiles.map((org)=> (
                                                <Stack direction={"row"} alignItems="center">
                                                    <Typography variant="caption">{org.organization_id}</Typography>
                                                    <IconButton onClick={()=> handleCopyClick(org.organization_id)}>
                                                        <ContentCopy/>
                                                    </IconButton>
                                                </Stack>
                                            ))} */}
                                        </Stack>
                                    }
                                >
                                    <AssignmentIndOutlined />
                                </CustomTooltip>
                                <Typography fontWeight={700}>Org ID</Typography>
                            </Stack>
    
                            <Typography><b>Created On:</b> &nbsp;{formatDate(userData.date_created.slice(0,10))}</Typography>
                           
                            {userData.date_updated && 
                                <Typography>
                                    <b>Updated On:</b> &nbsp;{formatDate(userData.date_updated.slice(0,10))}
                                </Typography> 
                            }
                            <Typography>
                            <b>DOB:</b> &nbsp;{userData.user_metadata?.dob? formatDate(userData.user_metadata.dob.slice(0, 10)) : 'N/A'}
                            </Typography>
    
                            <Typography>
                            <b>Height:</b> &nbsp;{userData.user_metadata?.height? `${userData.user_metadata.height.value} ${userData.user_metadata.height.unit}` : 'N/A'}
                            </Typography>
    
                            <Typography>
                            <b>Weight:</b> &nbsp;{userData.user_metadata?.weight? `${userData.user_metadata.weight.value} ${userData.user_metadata.weight.unit}` : 'N/A'}
                            </Typography>
    
                            <Typography>
                            <b>Leg:</b> &nbsp;{userData.user_metadata?.dominant_leg? userData.user_metadata.dominant_leg.slice(0, 1).toUpperCase() : 'N/A'}
                            </Typography>
    
                            <Typography>
                            <b>Arm:</b> &nbsp;{userData.user_metadata?.dominant_arm? userData.user_metadata.dominant_arm.slice(0, 1).toUpperCase() : 'N/A'}
                            </Typography>
                          
                            {userData.device_list.length > 0 &&
                                <Button onClick={()=> setViewDeviceList(true)}>
                                    <Typography fontWeight={700}>
                                        View Device List
                                    </Typography>
                                </Button>
                            }   
                        </Stack>
                    <Divider/>
                </Grid>
                <Modal
                    open={viewDeviceList}
                    hideBackdrop={false}
                    onClose={handleCloseDeviceList}
                >
                    <Card sx={{...commonModalStyle, }}>
                        <CardHeader 
                            title={`Device List`}
                            action={
                                <IconButton onClick={handleCloseDeviceList}>
                                    <Close/>
                                </IconButton>
                            }
                        />
                        <Box sx={{ maxHeight: '55vh', overflowY: 'auto' }}>
                        <UserDeviceList list={userData.device_list} />
                    </Box>
                    </Card>
                </Modal>
    
            <Stack maxWidth={'100vw'} p={2}>
                <Tabs value={selectedOrgId} onChange={handleAvatarClick} variant="scrollable">        
                    {orgDataList.map((org, index) => (
                        <Tab 
                            key={index} 
                            value={org.orgId}
                            label={
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Avatar 
                                        src={org.s3} 
                                        style={{
                                            border: selectedOrgId === org.orgId ? 
                                                '2px solid #FFC04D' 
                                                :
                                                '0'
                                        }}
                                    />
                                    <Typography>{org.name}</Typography>
                                </Stack>
                            } 
                        />
                    ))}
                </Tabs>
                {sessionHistory && userOrgData &&
                    orgDataList.map((org, index) => (
                        <TabPanel value={org.orgId}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                        <Typography variant="h5">{org.name}</Typography>
                                        <IconButton onClick={()=> handleShowJson(userOrgData, window)}>
                                            <DataObject/>
                                        </IconButton>
                                        <IconButton onClick={()=> setViewEditModal(true)}>
                                            <EditOutlined/>
                                        </IconButton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">User Info:</Typography>
                                    <Typography variant="body1">Created Date: {formatDate(userOrgData.date_created)}</Typography>
                                    <Typography variant="body1">Expires On: {formatDate(calculateExpiration(userOrgData.date_created, userOrgData.date_duration).expirationDate)}</Typography>
                                    <Typography variant="body1">Email: {userOrgData.email}</Typography>
                                    <Typography variant="body1">User Type: {userOrgData.type}</Typography>
                                    <Typography variant="body1">Full Name: {userOrgData.name}</Typography>
                                    <Typography variant="body1">First Name: {userOrgData.first_name}</Typography>
                                    <Typography variant="body1">Last Name: {userOrgData.last_name}</Typography>
                                </Grid>
                  
                                <Grid item xs={6}>
                                    <Typography variant="h6">Admin Settings:</Typography>
                                    <Typography variant="body1">Level: {userOrgData.admin_settings.level}</Typography>
                                    <Typography variant="body1">Status: {userOrgData.admin_settings.status}</Typography>
    
                                    <Typography variant="h6" mt={1}>Session Metadata:</Typography>
                                    <Typography variant="body1">Total Sessions: {userOrgData.session_history_metadata.total_sessions}</Typography>
                                    <Typography variant="body1">Total Duration: {userOrgData.session_history_metadata.total_duration}</Typography>
    
                                    <Typography variant="h6" mt={1}>Download Access:</Typography>
                                    <Typography variant="body1">
                                        Download Analyzed: {downloadAnalyzed}
                                    </Typography>
                                    <Typography variant="body1">
                                        Download Raw: {downloadRaw}
                                    </Typography>
                                    <Typography variant="body1">
                                        Download Metadata: {downloadMetadata}
                                    </Typography>
                                </Grid>
    
                                <Grid item xs={12}>
                                    <Typography variant="h6">Session History:</Typography>
                                    <UserSessionHistory list={sessionHistory} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    ))
                }
            </Stack>
            <EditUser 
                openModal={viewEditModal} 
                handleCloseModal={handleCloseModal} 
                orgProfile={userOrgData}
                snackbarState={snackbarState} 
                setSnackbarState={handleSnackbarState}
                />
                </Grid>
                <Snackbar 
                open={open} 
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                key={vertical + horizontal}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {text}
                    </Alert>
                </Snackbar>
                <EditMetadata 
                    openModal={openMetadataModal} 
                    handleCloseModal={()=> setOpenMetadataModal(false)} 
                    userData={userData}
                    />
            </Container>
        );
    }
}