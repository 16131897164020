import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
// @mui
import {
  Table,
  Badge,
  Stack,
  Paper,
  Snackbar,
  Alert,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Avatar,
  Popover,
  MenuItem,
  Divider,
  List, ListItem,
  Grid
} from '@mui/material';
import { ContentCopy, AssignmentIndOutlined, WarningOutlined, EditOutlined, MoreVertOutlined, DataObject } from '@mui/icons-material';
// sections
import { EditMetadata } from '../sections/org'
import { UsersListHead, UsersListToolbar, EditUser } from '../sections/users';
// API Calls
import { listAllOrgUsers, putUserOrgDateDuration, updateExpiredToken } from "../api/users";
// Utils
import { CustomTooltip } from '../utils/customStyles';
import { formatDate, calculateExpiration } from '../utils/formatFunctions';
import { handleCopyClick } from '../utils/copyFunction';
import { validateUserObject, validateOrgUserProfile } from '../utils/validateFunctions';
import { handleShowJson } from '../utils/jsonFunction';
import { listAllOrgs } from "../api/orgs";
// ----------------------------------------------------------------------

const tableHead = [
  { id: 'name', label: 'Name', alignRight: false },  
  { id: 'organizations', label: 'Organizations', alignRight: false },
  { id: 'email', label: 'Contact', alignRight: false },
  { id: 'metaData', label: 'Metadata', alignRight: false },
  { id: 'recentOrg', label: 'Recent Org', alignRight: false },
  { id: 'menu', label: '', alignRight: false },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  
  if (query) {
    return stabilizedThis.filter(([user]) => {
      const { name, email, user_id, org_profiles } = user;
      const lowercaseQuery = query.toLowerCase();
      return (
        name.toLowerCase().includes(lowercaseQuery) ||
        email.toLowerCase().includes(lowercaseQuery) ||
        user_id.toLowerCase().includes(lowercaseQuery) ||
        org_profiles.some(org => org.organization_id.toLowerCase().includes(lowercaseQuery))
      );
    }).map(([filteredUser]) => filteredUser);
  }
  
  return stabilizedThis.map(([el]) => el);
}

export default function Organizations () {
  const [openModal, setOpenModal] = useState(null);

  const [openMetadataModal, setOpenMetadataModal] = useState(null)

  const [openMenu, setOpenMenu] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [userData, setUserData] = useState(null)

  const [orgProfile, setOrgProfile] = useState(null)

  const [userJson, setUserJson] = useState('')

  const [openOrgList, setOpenOrgList] = useState(false)

  const [orgList, setOrgList] = useState([])

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    text: ""
  });

  const { vertical, horizontal, open, text } = snackbarState;

  useEffect(()=> {

    const fetchUserData = async() => {
        try {
            const data = await listAllOrgUsers()
            const orgs = await listAllOrgs()
            const filteredOrgs = orgs.data.filter(org=> org.sk_id !== "menu")

            // Array of objects of orgs, each org has a s3_logo_ulr key and an organization_id 
            // I need to match the orgs to the org profiles and then add the s3logo url to the org_profile
            const users = data.data
            const userOrgs = users.filter((user) => user.organization_id);

            userOrgs.forEach(async(org)=> {
              if (!org.hasOwnProperty('date_duration')) {
                console.log("No date duration!")
                console.log(org)
                const data = {
                  pkId: org.pk_id,
                  skId: org.sk_id
                }
                const response = await putUserOrgDateDuration(data)
                console.log(response)
              }

              // || !org.hasOwnProperty('expired') need to add function for this
              // if org has the property date duration and expired then check if they are expried and then set that toggle 
              if (org.hasOwnProperty('date_duration')){
                const remainingMembershipTime = calculateExpiration(org.date_created, org.date_duration)
                const data = {
                  pkId: org.pk_id,
                  skId: org.sk_id
                }
                if(remainingMembershipTime.expired) {
                  if(!org.hasOwnProperty('expired') || org.expired === false){
                    const response = await updateExpiredToken(data)
                    console.log(response, "Expired Token updated", org)
                  }
                }
              }
            })

            const userProfiles = users.filter((user) => user.organization_id === undefined);

            const sortedUserOrgProfiles = userOrgs.sort((a, b) => a.user_id.localeCompare(b.user_id));

            // Sort user profiles by user ID
            const sortedUserProfiles = userProfiles.sort((a, b) => a.user_id.localeCompare(b.user_id));
        
            // Match user IDs between user profiles and organization profiles
            const matchedUserProfiles = sortedUserProfiles.map((userProfile) => {
              const org_profiles = sortedUserOrgProfiles
                .filter((orgProfile) => orgProfile.user_id === userProfile.user_id)
                .map((orgProfile) => {
                  const org = filteredOrgs.find(
                    (org) => org.organization_id === orgProfile.organization_id
                  );
                  return {...orgProfile, s3_logo_url: org.s3_logo_url };
                });
            
              
              return { ...userProfile, org_profiles };
            });

            setUserData(matchedUserProfiles)
        } catch(err) {
            console.log(err)
        }
    }

    fetchUserData()
  }, [openModal, openMetadataModal])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleOpenModal = (user) => {
    setOrgProfile(user)
    setOpenModal(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenMenu = (event, data) => {
    setOpenMenu(event.currentTarget);
    setUserJson(data)
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleSnackbarState = () => {
    setSnackbarState({ 
      ...snackbarState, 
      open: true, 
      text: "The User Org Profile has been successfully updated!" 
    });
    setOpenModal(false)
}

  if (userData) {

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;
  
    const filteredUsers = applySortFilter(userData, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    return (
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Users
          </Typography>
        </Stack>

        <UsersListToolbar filterName={filterName} onFilterName={handleFilterByName} />

        <TableContainer>
          <Table>
            <UsersListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={userData.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, name, date_created, user_id, email, recent_org, org_profiles } = row;
                const missingdata = validateUserObject(row)
                // console.log(row)
                const { org_profiles: _, ...ddbentry } = row;

                let warningClass = 'transparent';
            
                if (missingdata.length > 0) {
                  const hasHeightOrWeight = missingdata.includes("Height" || "Weight")
                  warningClass = hasHeightOrWeight ? 'error.main' : 'error.light';
                }

                return (
                  <TableRow key={id} tabIndex={-1}>
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <CustomTooltip 
                          title={ 
                            <Stack direction={"row"} alignItems="center">
                              <Typography variant="caption">Copy User ID</Typography>
                              <IconButton onClick={()=> handleCopyClick(user_id)}>
                                <ContentCopy />
                              </IconButton>
                            </Stack>
                          }
                        >
                          <AssignmentIndOutlined />
                        </CustomTooltip>
                        <Link 
                          to={`/dashboard/user/${name}`}  
                          style={{textDecoration: "none", color: "black"}}
                          state= {{ 
                            userName: name,
                            userId: user_id,
                            orgProfiles: org_profiles,
                            userData: row, 
                          }}
                        >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Stack>
                              <Typography noWrap fontWeight={700}>
                                {name}
                              </Typography>
                        
                              <Typography noWrap>Created On: {formatDate(date_created)}</Typography>
                            </Stack>
            
                            {missingdata.length > 0 && 
                              <CustomTooltip 
                                title={
                                  <Typography variant="caption">
                                    {missingdata}
                                  </Typography>
                                }
                              >
                                <WarningOutlined sx={{color: warningClass}}/>
                              </CustomTooltip>
                            }
                          </Stack>
                        </Link>
                      </Stack>
                    </TableCell>

                    <TableCell align="left">
                      <Stack direction={"row"} spacing={1}>
                        {org_profiles && org_profiles.length <= 3 && org_profiles.map((org) => {
                          const validOrgProfile = validateOrgUserProfile(org)
                
                          return (
                            <OrgAvatar org={org} handleOpenModal={handleOpenModal}/>
                          )
                        })}
                        {org_profiles && org_profiles.length > 3 &&
                            <>
                              <OrgAvatar org={org_profiles[0]} handleOpenModal={handleOpenModal}/>
                              <OrgAvatar org={org_profiles[1]} handleOpenModal={handleOpenModal}/>
                              <Avatar onClick={(event) => {
                                setOpenOrgList(event.currentTarget);
                                setOrgList(org_profiles.slice(2));
                              }}
                              sx={{
                                cursor: 'pointer'
                              }}>
                                {`+ ${org_profiles.length - 2}`} 
                              </Avatar>
                            </>
                          }
          
                      </Stack>
                    </TableCell>

                    <TableCell align="left">
                      <Typography>{email}</Typography>
                    </TableCell>

                    <TableCell align="left">
        
                      <Typography>
                      <b>DOB:</b> &nbsp;{row.user_metadata?.dob? formatDate(row.user_metadata.dob.slice(0, 10)) : 'N/A'}
                      </Typography>

                      <Typography>
                      <b>Height:</b> &nbsp;{row.user_metadata?.height? `${row.user_metadata.height.value} ${row.user_metadata.height.unit}` : 'N/A'}
                      </Typography>

                      <Typography>
                      <b>Weight:</b> &nbsp;{row.user_metadata?.weight? `${row.user_metadata.weight.value} ${row.user_metadata.weight.unit}` : 'N/A'}
                      </Typography>

                      <Typography>
                      <b>Leg:</b> &nbsp;{row.user_metadata?.dominant_leg? row.user_metadata.dominant_leg.slice(0, 1).toUpperCase() : 'N/A'}
                      </Typography>

                      <Typography>
                      <b>Arm:</b> &nbsp;{row.user_metadata?.dominant_arm? row.user_metadata.dominant_arm.slice(0, 1).toUpperCase() : 'N/A'}
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Typography>{recent_org ? recent_org.split('-')[1] : "N/A" }</Typography>
                    </TableCell>
                    
                    <TableCell align="left">
                      <IconButton onClick={(e)=> handleOpenMenu(e, ddbentry)}>
                        <MoreVertOutlined/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                    <Typography variant="h6" paragraph>
                      Not found
                    </Typography>

                    <Typography variant="body2">
                      No results found for &nbsp;
                      <strong>&quot;{filterName}&quot;</strong>.
                    </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
          component="div"
          count={userData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <EditUser 
          openModal={openModal} 
          handleCloseModal={()=> setOpenModal(false)} 
          orgProfile={orgProfile}
          snackbarState={snackbarState} 
          setSnackbarState={handleSnackbarState}
        />
        <EditMetadata 
          openModal={openMetadataModal} 
          handleCloseModal={()=> setOpenMetadataModal(false)} 
          userData={userJson}
        />
          {/* Pull out */}
          
        <Snackbar 
          open={open} 
          anchorOrigin={{ vertical, horizontal }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {text}
          </Alert>
        </Snackbar>
        <Popover
          open={Boolean(openOrgList)}
          anchorEl={openOrgList}
          onClose={() => {
            setOpenOrgList(null);
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Stack direction={"row"}>
            <Grid container>
              {orgList.map((org) => {
                return (
                  <Grid item>
                    <ListItem>
                      <OrgAvatar org={org} handleOpenModal={handleOpenModal} />
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Popover>
        <Popover
          open={Boolean(openMenu)}
          anchorEl={openMenu}
          onClose={()=> { setOpenMenu(null)}}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem>
            <Typography>View JSON</Typography>
            <IconButton onClick={() => handleShowJson(userJson)}>
              <DataObject/>
            </IconButton>
          </MenuItem>

          <MenuItem>
            <Typography>Edit Users Metadata</Typography>
            <IconButton onClick={() => setOpenMetadataModal(true)}>
              <EditOutlined/>
            </IconButton>
          </MenuItem>
        </Popover>
      </Container>
    );
  }
}


const OrgAvatar = ({ org, handleOpenModal }) => {
  const { differenceInDays } = calculateExpiration(org.date_created, org.date_duration);
  const expirationColor = (() => {
    if (differenceInDays > 30) {
      return 'success.dark';
    }
    if (differenceInDays > 0) {
      return 'warning.main';
    }
    return 'error.main';
  })();

  const {s3_logo_url: _, ...data}  = org

  return (
    <CustomTooltip 
      title={
        <List disablePadding dense>
          <ListItem>
            <Typography textAlign={"center"} variant="caption">{org.organization_id.split("-")[1]}</Typography>
          </ListItem>

          <Divider />

          <ListItem>
            <Typography variant='caption'> View JSON </Typography>
            <IconButton onClick={() => handleShowJson(data)}>
              <DataObject/>
            </IconButton>
          </ListItem>

          <Divider/>

          <ListItem>
            <Typography variant='caption'> Edit User </Typography>
            <IconButton onClick={() => handleOpenModal(org)}>
              <EditOutlined />
            </IconButton>
          </ListItem>

          <Divider/>
          
          <ListItem>
            <Typography variant='caption'> Copy Org ID </Typography>
            <IconButton onClick={()=> handleCopyClick(org.organization_id)}>
              <ContentCopy />
            </IconButton>
          </ListItem>
        </List>   
      }
    >
      <Badge
        variant="dot"
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: expirationColor,
            color: expirationColor,
            boxShadow: `0 0 0 2px white`,
          },
        }}
      >
        <Avatar
          style={{
            border: org.type === "admin" ?
              '3px solid #FFC04D' 
              :
              '0'
          }}
          src={org.s3_logo_url}
        />
      </Badge>
    </CustomTooltip>
  );
};
