import { Chip } from "@mui/material";
import { formatDate } from "./formatFunctions";

export const createExpiringOrgs = (sortedOrgsByDaysLeft, orgMenus) => {
    return sortedOrgsByDaysLeft.map((org) => ({
      title: org.name,
      link: `/dashboard/org/${org.name}`,
      image: org.s3_logo_url,
      caption: `${org.days_left} days left`,
      description: (
        <Chip
          variant="outlined"
          label={org.org_type}
          color={org.org_type === "trial"? "primary" : "success"}
        />
      ),
      state: {
        orgName: org.name,
        orgId: org.organization_id,
        orgMenus,
      },
    }));
  };


export const createNewUserList = (usersJoinedThisWeek) => {

    return usersJoinedThisWeek.map((user) => ({
       title: user.name,
       link: `/dashboard/user/${user.name}`,
       description: user.org_profiles[0].organization_id,
       color: "primary",
       caption: `Joined ${formatDate(user.date_created)}`,
       state: {
           userId: user.user_id,
         }
   }))
}



export const createExpiringUsers = (sortedOrgProfiles)=> {
    return sortedOrgProfiles.map((expiringUser) => ({
       title: expiringUser.name,
       link: `/dashboard/user/${expiringUser.name}`,
       description: expiringUser.organization_id,
       color: "error",
       caption: `Expiring ${formatDate(expiringUser.expiration_date)}`,
       state: {
         userId: expiringUser.user_id,
       },
   }));
}



export function processActivityData(data) {
    // Convert input data to an array of [date, activityData] pairs
    const entries = Object.entries(data);
    console.log(entries)
    // Sort entries by date
    entries.sort((a, b) => new Date(a[0]) - new Date(b[0]));

    const cumulativeData = {};
    const cumulativeSums = {};

    // Iterate through the sorted entries
    entries.forEach(([date, activities]) => {
        if (!cumulativeData[date]) {
            cumulativeData[date] = {};
        }

        for (const [activity, stats] of Object.entries(activities)) {
            if(activity === 
              "A-UUID-dummy1-JUMPING" 
              // ||
              // "A-WHEELCHAIR_RACING_17.0" ||
              // "A-WHEELCHAIR_RACING_13.0" ||
              // "A-WHEELCHAIR_RACING_13.5" ||
              // "A-WHEELCHAIR_RACING_14.5"
            ){
                continue;
            }
            if (!cumulativeSums[activity]) {
                cumulativeSums[activity] = { duration: 0, reps: 0, count: 0 };
            }

            // Update cumulative sums
            cumulativeSums[activity].duration += stats.duration;
            cumulativeSums[activity].reps += stats.reps;
            cumulativeSums[activity].count += stats.count;

            // Store the cumulative sums in the cumulativeData object
            cumulativeData[date][activity] = { ...cumulativeSums[activity] };
        }
    });

    return cumulativeData;
}


export const calculateSessionData = (orgSessions) => {
    const sessionData = orgSessions.reduce((acc, element) => {
      const date = element.date.slice(0, 10);
  
      acc[date] = acc[date] || { reps: 0, count: 0 };
      acc[date].reps += element.session_metrics.reps;
      acc[date].count += 1;
  
      return acc;
    }, {});
  
    const sortedDates = Object.keys(sessionData).sort((a, b) => new Date(a) - new Date(b));
  
    let sessionsum = 0;
    let repssum = 0;
  
    const summedSessions = {};
    const summedReps = {};
  
    for (const date of sortedDates) {
      sessionsum += sessionData[date].count;
      summedSessions[date] = sessionsum;
  
      repssum += sessionData[date].reps;
      summedReps[date] = repssum;
    }

    // Activity data
    const activityData = orgSessions.reduce((acc, session) => {
        const { date, activity_list: [{ activity: { activity_name: activity } }] } = session;
        const { duration, reps } = session.session_metrics;
        const dateKey = date.slice(0, 10);
        acc[dateKey] = acc[dateKey] || {};

        if(acc[dateKey][activity] !== undefined) {
            acc[dateKey][activity].duration += duration;
            acc[dateKey][activity].reps += reps;
            acc[dateKey][activity].count++;
            
        } else {
            acc[dateKey][activity] = { duration: duration, reps: reps, count: 1 };
        }
        
        return acc;
    }, {});
  
    return { summedSessions, summedReps, activityData, totalSessions: orgSessions.length };
  };


  export function prepareChartData(cumulativeData, key) {
    const activities = new Set();
    const dates = Object.keys(cumulativeData).sort((a, b) => new Date(a) - new Date(b));
    
    // Extract all activities to handle each one separately
    dates.forEach(date => {
        Object.keys(cumulativeData[date]).forEach(activity => activities.add(activity));
    });

    const chartLabels = dates;
    let chartData = [];

    activities.forEach(activity => {
      let lastKnownReps = 0;
      const data = dates.map(date => {
        if (cumulativeData[date][activity]) {
            lastKnownReps = cumulativeData[date][activity][key];
            return lastKnownReps;
        } else {
            return lastKnownReps;
        }
      });

      chartData.push({
        name: activity,
        data: data
      });
    });

    return { chartLabels, chartData };
}