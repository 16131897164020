import { Link } from 'react-router-dom';
// @mui
import { Box, Stack, Card, Divider, Typography, CardHeader, Button, CardActions, CardContent } from '@mui/material';
// ----------------------------------------------------------------------

export default function StatsList ({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>

      <CardHeader title={title} subheader={subheader}/>

      <CardContent sx={{ maxHeight: "50vh", overflow: "scroll" }}>
        <Stack spacing={3} p={1}>
          {list && list.map((statistic) => (
            <StatisticItem key={statistic.id} statistic={statistic}/>
          ))}
        </Stack>
      </CardContent>

      <Divider />

      {/* <CardActions sx={{ p: 2, textAlign: 'right' }}>
        <Button size="small" color="inherit">
          View all
        </Button>
      </CardActions> */}
    </Card>
  );
}

// ----------------------------------------------------------------------

function StatisticItem({ statistic }) {

  const { title, link, state, description, image, caption } = statistic

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        { image && 
          <Box 
            component="img"
            alt={title.slice(0,1)} 
            src={image} 
            sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0}} 
          /> 
        }

        <Box sx={{ minWidth: 240, flexGrow: 1 }}>
          <Link 
            style={{textDecoration: "none", color: "black"}}
            to={link}                      
            state={{ state }}
          >
            <Typography color="inherit" variant="subtitle2" underline="hover" >
              {title}
            </Typography>
          </Link>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {description}
          </Typography>
        </Box>

        <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
          {caption}
        </Typography>
      </Stack>
      <Divider/>
    </>
  );
}
