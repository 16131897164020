import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ImageCompressor from 'image-compressor.js';
// @mui
import { Typography, Stack, Container, TextField, Button, Box, Snackbar, 
  InputAdornment, IconButton, Alert, Grid, Chip, FormControl, FormControlLabel,
  FormLabel, Radio, RadioGroup, Divider, Switch, MenuItem, InputLabel, Select
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { CloudUploadOutlined, Search, CheckCircle} from '@mui/icons-material';
// APIs
import { createOrganization, inviteUserToOrganization, upgradeUserToAdminViaEmail } from "../api/orgs";
// Utils
import { getTimezoneOffsetString, toISOLocal, isInteger } from "../utils/formatFunctions";
// ----------------------------------------------------------------------

export default function CreateOrg () {
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState("")
  const [organizationNameHelperText, setOrganizationNameHelperText] = useState("")
  const [organizationName, setOrganizationName] = useState(null)
  const [organizationImage, setOrganizationImage] = useState(null);
  const [expirationDate, setExpirationDate] = useState(365)
  const [organizationUserCount, setOrganizationUserCount] = useState(9)
  const [organizationAdminCount, setOrganizationAdminCount] = useState(3)
  const [notes, setNotes] = useState("")
  const [fileImage, setFileImage] = useState(null);
  const [organizationId, setOrganizationId] = useState('')
  const [showSuccessfulOrgCreation, setShowSuccessfulOrgCreation] = useState(false)
  const [showSuccessfulOrgInvite, setShowSuccessfulOrgInvite] = useState(false)
  const [downloadAnalyzed, setDownloadAnalyzed] = useState(false)
  const [downloadRaw, setDownloadRaw] = useState(false)
  const [downloadMeta, setDownloadMeta] = useState(false)
  const [stepOneDisabled, setStepOneDisabled] = useState(false)
  const [stepTwoDisabled, setStepTwoDisabled] = useState(true)
  const [loadingInvitingAthletes, setLoadingInvitingAthletes] = useState(false)
  const [emailValue, setEmailValue] = useState("")
  const [menuError, setMenuError] = useState(false)
  const [metricSelection, setMetricSelection] = useState(null)
  const [metricMenus, setMetricMenus] = useState([])
  const [orgTypeSelection, setOrgTypeSelection] = useState('trial');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    text: ""
  });
  const [emailData, setEmailData] = useState([
    { key: 0, email: 'shared@nextiles.tech', color: "primary" },
  ]);

  const filesInputRef = useRef(null);
  const { vertical, horizontal, open, text } = snackbarState;
  useEffect(()=> {
    if (location.state.orgMenus) {
      setMetricMenus(location.state.orgMenus)
    }
  },[])

  const onChange = async (event) => {
      try {
          const file = event.target.files[0];
          setFileImage(file)
          // const image = await resizeFile(file);
          // setFileImage(image)
          const reader = new FileReader();

          reader.onload = () => {
            const imageData = reader.result;
            setOrganizationImage(imageData)
            // setFileImage(imageData)
          };
          
          // Read the file as a data URL
          reader.readAsDataURL(file);
      } catch(err) {
          console.log(err);
      }
  }

  const handleSuccessfulOrgCreation = (organization_id) => {
    setOrganizationImage(null)
    setOrganizationName(null)
    setSnackbarState({ 
      ...snackbarState, 
      open: true, 
      text: "The Organization has been successfully created!" 
    });
    setStepOneDisabled(true)
    setStepTwoDisabled(false)
    setOrganizationId(organization_id)
    setShowSuccessfulOrgCreation(true)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const compressImage = (imageFile) => {
    return new Promise((resolve, reject) => {
        new ImageCompressor(imageFile, {
            quality: 0.6, // Adjust compression quality (0 to 1)
            maxWidth: 800, // Set maximum width for the compressed image
            maxHeight: 600, // Set maximum height for the compressed image
            success(result) {
                resolve(result);
            },
            error(error) {
                console.error('Error compressing image:', error);
                reject(error);
            },
        });
    });
  };

  const handleOrgCreation = async() => {
    const timezoneOffset = getTimezoneOffsetString();
    const date = new Date()
    const formattedDate = toISOLocal(date)
    // console.log(fileImage)

    const compressedImageBlob = await compressImage(fileImage);
    // console.log(compressedImageBlob)
    const imageData = await readImageData(compressedImageBlob);

    const orgData = {
      expirationDate: expirationDate,
      maxUsers: organizationUserCount,
      maxAdmin: organizationAdminCount,
      orgType: orgTypeSelection,
      notes,
      metricSelection,
      downloadAnalyzed,
      downloadRaw,
      downloadMeta,
      organizationName,
      organizationImage: imageData,
      timezoneOffset,
      formattedDate
    }

    console.log(orgData)
    const response = organizationName.length > 3 ? await createOrganization(orgData) : { success: false}
    console.log(response)
    response.success === true ? handleSuccessfulOrgCreation(response.organization_id) : setErrorMessage("Org was not created!")
  }

  const readImageData = (imageBlob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
          const imageData = reader.result;
          resolve(imageData);
      };
      reader.onerror = (error) => {
          reject(error);
      };
      reader.readAsDataURL(imageBlob);
    });
};

  const handleOrgTypeChange = (event) => {
    setOrgTypeSelection(event.target.value);
  };

  const handleSubmit = async (e) => {

    if (!organizationName) {
      setErrorMessage("Please Enter an Organization Name!")
    } else if (!organizationImage) {
      setErrorMessage("Please Upload an Organization Image!")
    } else if (!metricSelection) {
      setErrorMessage("Please select a metric menu!")
      setMenuError(true)
    } else {
      setErrorMessage("")
      handleOrgCreation()
      setMenuError(false)
    }
  }

  const handleOrganizationNameChange = (e) => {
    const orgName = e.target.value
    setOrganizationName(orgName)

    const trimmedOrgName = orgName.trim()
    trimmedOrgName.length <= 2 ? 
    setOrganizationNameHelperText("Please enter a minimum of 3 characters.") 
    : setOrganizationNameHelperText("")
  }

  const handleEmailChange = (e) => {
    const value = e.target.value
    //Validate email
    setEmailValue(value)
  }

  const addEmailData = () => {
    setEmailData([...emailData, { key: emailData.length, email: emailValue, color: "primary" }])
    setEmailValue("")
  }

  const handleDelete = (chipToDelete) => () => {
    setEmailData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const inviteAdmins = async() => {
    setLoadingInvitingAthletes(true)
    const updatedEmailData = emailData.map(async(email) => {
      // Invite User to Org
      console.log(organizationId)
      const userResponse = await inviteUserToOrganization(email.email, organizationId)
      const inviteUserStatus = userResponse.status
      console.log(inviteUserStatus)
      if (inviteUserStatus !== 200) {
         email.color = "error"
         return email
      } else  {
        // Then Upgrade User to Admin
        const adminResponse = await upgradeUserToAdminViaEmail(email.email, organizationId)
        const adminStatus = adminResponse.status

        if(adminStatus === 200) {
          email.color = "success"
          return email
        } else {
          email.color = "error"
          return email
        }
      }
    })
    console.log(updatedEmailData)
    setEmailData(await Promise.all(updatedEmailData));
    // setShowSuccessfulOrgInvite(true)
    // setSnackbarState({ 
    //   ...snackbarState, 
    //   open: true, 
    //   text: "Admins have been successfully invited!" 
    // });
    setLoadingInvitingAthletes(false)
  }

  const handleMetricMenuChange = (e) => {
    setMetricSelection(e.target.value)
  }

  const handleNumericalFieldChange = (event, setter) => {
    const val = event.target.value;
    if (isInteger(val)){
      setter((val === "" || val === "-") ? 0 : parseInt(val));
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container > 
        <Grid item xs={5.5} pl={1}>
          <Typography variant="h5" display="flex" alignItems="center">
            Step 1 - Create a New Organization
            {showSuccessfulOrgCreation && (<CheckCircle color="success" ml={2}/>)}
          </Typography>

          <Typography variant="body1" mb={1} mt={3}>Create Organization</Typography>

          <Stack spacing={3} component="form">

            <TextField 
              required
              label="Organization Name" 
              value={organizationName} 
              disabled={stepOneDisabled}
              onChange={handleOrganizationNameChange}
              helperText={organizationNameHelperText}
            />

            <FormControl required>      
              <InputLabel>Metric Menus</InputLabel>
              <Select
                value={metricSelection}
                onChange={handleMetricMenuChange}
                fullWidth
                disabled={stepOneDisabled}
                error={menuError}
                label="Metric Menu"
              >
                {metricMenus.map((menu)=> (
                  <MenuItem value={menu.pk_id}>{menu.display_name}</MenuItem>
                ))}
              </Select>
            </FormControl>


            <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>

              <TextField 
                label="Max User Count" 
                fullWidth
                required
                value={organizationUserCount} 
                onChange={(event) => handleNumericalFieldChange(event, setOrganizationUserCount)}
              />

              <TextField 
                label="Max Admin Count" 
                fullWidth
                required
                value={organizationAdminCount} 
                onChange={(event) => handleNumericalFieldChange(event, setOrganizationAdminCount)}
              />

              <TextField 
                fullWidth
                required
                label="Expiration Days" 
                value={expirationDate} 
                onChange={(event) => handleNumericalFieldChange(event, setExpirationDate)}
              />
            </Stack>

            <FormControl required>
              <FormLabel>Type of Org</FormLabel>
              <RadioGroup row value={orgTypeSelection} onChange={handleOrgTypeChange}>
                <FormControlLabel value="trial"  control={<Radio disabled={stepOneDisabled}/>} label="Trial" />
                <FormControlLabel value="member" control={<Radio disabled={stepOneDisabled}/>} label="Member" />
                <FormControlLabel value="internal" control={<Radio disabled={stepOneDisabled}/>} label="Internal" />
              </RadioGroup>
            </FormControl>

          <Stack direction="row" spacing={2}>

            <FormControl required>
              <FormLabel>Download Analyzed</FormLabel>
                <FormControlLabel control={<Switch checked={downloadAnalyzed} disabled={stepOneDisabled} onChange={(e) => { setDownloadAnalyzed(e.target.checked)}}/>} />
            </FormControl>

            <FormControl required>
              <FormLabel>Download Raw</FormLabel>
                <FormControlLabel control={<Switch checked={downloadRaw} disabled={stepOneDisabled} onChange={(e) => { setDownloadRaw(e.target.checked)}}/>} />
            </FormControl>

            <FormControl required>
              <FormLabel>Download Meta</FormLabel>
                <FormControlLabel control={<Switch checked={downloadMeta} disabled={stepOneDisabled} onChange={(e) => { setDownloadMeta(e.target.checked)}}/>} />
            </FormControl>
          </Stack>

            <TextField label="Notes" value={notes} onChange={((e) => {setNotes(e.target.value)})} disabled={stepOneDisabled}/> 

            <Box
              onClick={() => filesInputRef.current.click()} 
              bgcolor={stepOneDisabled ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 192, 77, 0.3)"}
              border={"1px dashed rgba(229, 173, 69, 1)"}
              borderRadius={"4px"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                p={3}
              >
                <Typography>
                  {organizationImage ? (
                    <Box
                      component="img"
                      borderRadius={"4px"}
                      src={organizationImage}
                      sx={{ maxHeight: 128, maxWidth: 128, mx: 'auto', my: 1 }}
                    />
                  ) : (
                    <CloudUploadOutlined sx={{fontSize: 95}}/>
                  )}
                </Typography>

                <Typography variant="button" fontSize={16} sx={{textDecoration: 'underline'}}>Upload Organization Logo</Typography>
                <Typography variant="body1">Supported Formats: PNG</Typography>
              </Box>
            </Box>
            <input type="file" accept="image/png" ref={filesInputRef} onChange={onChange} style={{ display: 'none' }}/> 

            <Typography color={"error.main"}>{errorMessage}</Typography>

            <Button
              variant="contained"
              onClick={(e)=> handleSubmit(e)}
              disabled={stepOneDisabled}
              sx={{width: "50%", alignSelf: 'center'}}
            >
              Create Org
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={1} display="flex" justifyContent="center">
          <Divider orientation="vertical"/>
        </Grid>

        <Grid item xs={5.5} pr={1}>
          <Typography variant="h5" display="flex" alignItems="center">
            Step 2 - Invite Admin
            {showSuccessfulOrgInvite && (<CheckCircle color="success" ml={2}/>)}
          </Typography>

          <Typography variant="body1" mb={1} mt={3}>Invite Admins via Email</Typography>

          <Stack spacing={3}>

            <Box 
              border={"1px dashed rgba(0, 0, 0, 0.12)"} 
              p={1} 
              minHeight={110}
              sx={{ display: 'flex', flexWrap: 'wrap' }}
            >
              {emailData.map((data) => {
                return (
                  <Chip
                    label={data.email}
                    variant="outlined"
                    color={data.color}
                    onDelete={(data.email === "shared@nextiles.tech" || data.color === "success") ? null : handleDelete(data)}
                    disabled={stepTwoDisabled}
                    sx={{backgroundColor: `${data.color}.light`, color: "black", marginRight: .5}}
                  />
                );
              })}
            </Box>

            <TextField
              fullWidth
              value={emailValue}
              onChange={handleEmailChange}
              disabled={stepTwoDisabled}
              placeholder={"Enter Email"}
              onKeyDown={(ev) => { if (ev.key === "Enter") addEmailData(); }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={addEmailData}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton  
              variant="contained"
              loading={loadingInvitingAthletes}
              onClick={inviteAdmins}
              disabled={stepTwoDisabled}
              sx={{width: "50%", alignSelf: 'center'}}
            >
              Invite Admins
            </LoadingButton>

          </Stack>

        </Grid>
      </Grid>
      
      <Snackbar 
        open={open} 
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {text}
        </Alert>
      </Snackbar>

    </Container>
  )
}