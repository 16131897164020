import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Typography, Divider } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import NavSection from '../../components/nav-section';
import { generalConfig, personalConfig } from '../nav/config'

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Box sx={{ px: 2 }}>
        <svg viewBox="0 0 600 180" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.05 136.337H20V89.6759C22.08 89.6759 24.14 89.6759 26.21 89.6759C27.52 89.6759 29.07 89.3334 30.09 89.8518C31.11 90.3703 31.62 91.7683 32.3401 92.7867C38.2401 101.23 44.1268 109.664 50.0001 118.089L50.8301 119.191V89.75H61.0002V136.365H59.7502C57.2702 136.365 54.7501 136.365 52.3101 136.365C51.9076 136.407 51.5009 136.335 51.1438 136.158C50.7866 135.981 50.4959 135.708 50.3101 135.375C43.8568 125.808 37.3834 116.263 30.89 106.739C30.69 106.452 30.45 106.165 30.05 105.637V136.337Z" fill="#262626"/>
          <path d="M308.001 98.8581H284.307V107.463H303.858V116.914H284.277V127.027H307.748V136.377H274.001V89.5647H308.001V98.8581Z" fill="#262626"/>
          <path d="M69.9999 89.5647H104V98.888H80.3085V107.467H98.8051V116.958H80.2983V127.072H103.878V136.377H69.9999V89.5647Z" fill="#262626"/>
          <path d="M351.099 94.3966C349.322 97.1175 347.633 99.7362 345.866 102.457C344.737 101.779 343.657 101.148 342.587 100.488C340.763 99.3255 338.686 98.5639 336.51 98.2597C334.996 98.0318 333.445 98.2087 332.033 98.7705C330.629 99.3555 329.529 100.136 329.362 101.724C329.194 102.535 329.312 103.376 329.698 104.118C330.085 104.86 330.719 105.461 331.502 105.828C332.799 106.426 334.15 106.914 335.538 107.286C339.789 108.484 343.804 110.128 347.064 113.034C351.207 116.749 353.023 121.262 351.423 126.537C349.774 132.006 345.945 135.823 339.965 136.872C332.013 138.265 324.846 136.167 318.592 131.301C317.728 130.623 316.923 129.861 316 129.053L323.206 122.59C323.933 123.184 324.62 123.769 325.347 124.317C328.697 126.91 332.996 128.145 337.305 127.753C338.332 127.672 339.307 127.293 340.097 126.668C340.888 126.043 341.456 125.203 341.723 124.261C342.39 122.516 341.615 121.123 340.319 119.971C338.257 118.243 335.774 117.024 333.093 116.424C330.42 115.829 327.874 114.808 325.563 113.406C320.575 110.165 318.69 105.577 319.515 100.071C320.261 94.9723 323.628 91.7128 328.577 89.8556C334.32 87.7476 339.907 88.5555 345.267 91.0628C347.231 91.9914 349.096 93.2079 351.07 94.3316" fill="#262626"/>
          <path d="M191.129 136.377H181.066V98.9326H167.002V89.5647H205.003V98.8768H191.129V136.377Z" fill="#262626"/>
          <path d="M237.001 136.377V89.5647H247.03V127.014H266.001V136.377H237.001Z" fill="#262626"/>
          <path d="M160.991 136.356C157.414 136.356 154.086 136.273 150.758 136.356C150.069 136.427 149.373 136.31 148.756 136.019C148.138 135.728 147.625 135.275 147.28 134.717C136.033 119.898 124.74 105.079 113.4 90.2593L113.001 89.7036C113.265 89.6362 113.535 89.5897 113.808 89.5647C117.356 89.5647 120.903 89.5647 124.441 89.5647C124.717 89.5927 124.985 89.6718 125.228 89.7975C125.471 89.9232 125.684 90.0928 125.856 90.2964C136.445 104.115 147.024 117.947 157.593 131.79L161.001 136.264" fill="#262626"/>
          <path d="M225.001 89.5647H215.001V136.377H225.001V89.5647Z" fill="#262626"/>
          <path d="M144.834 111.547C142.886 108.865 140.938 106.315 139.068 103.727C139.01 103.573 138.989 103.408 139.008 103.244C139.026 103.081 139.084 102.924 139.176 102.786C142.273 98.5075 145.396 94.2415 148.545 89.9881C148.648 89.8648 148.777 89.7635 148.922 89.6907C149.068 89.6178 149.228 89.5749 149.392 89.5647C153.161 89.5647 156.94 89.5647 161.001 89.5647L144.834 111.594" fill="#FFC04D"/>
          <path d="M113.001 136.378L129.152 114.349C131.099 117.013 133.046 119.583 134.886 122.171C134.962 122.318 135.001 122.479 135.001 122.643C135.001 122.807 134.962 122.969 134.886 123.116C131.804 127.367 128.695 131.608 125.56 135.84C125.368 136.103 125.078 136.282 124.752 136.341C120.945 136.341 117.139 136.341 113.069 136.341" fill="#FFC04D"/>
        </svg>
      </Box>

      <Box sx={{ width: "fit-content"}} bgcolor={"primary.main"} borderRadius={1} mb={2} ml={3}>
        <Typography variant='h6' px={1}>Super-Admin</Typography>
      </Box>

      <Divider mt={2}/>

      <Typography pl={2} mt={2}>General</Typography>

      <NavSection data={generalConfig} mb={1} />

      <Divider mt={2}/>

      <Typography pl={2} mt={2}>Personal</Typography>

      <NavSection data={personalConfig}/>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
