import React, { useEffect, useState } from 'react';
// @mui
import { 
  Stack, Typography, Table, MenuItem, TableCell, TableRow, TableContainer, 
  TableBody, IconButton, Toolbar, TablePagination, Popover, TextField, InputAdornment
} from '@mui/material';
import { 
  ContentCopy, InfoOutlined, AssignmentIndOutlined, 
  MoreVert, WarningOutlined, Cancel, ReportProblem, CheckCircle, Search, DataObject
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
// APIs
import { demoteAdminToUser, getUserMetadata } from '../../api/users';
// Components
import { OrgListHead } from '../orgs';
import { EditMetadata } from '../org'
// Utils
import { handleShowJson } from '../../utils/jsonFunction';
import { handleCopyClick } from '../../utils/copyFunction';
import { formatDate, calculateExpiration } from '../../utils/formatFunctions';
import { CustomTooltip } from '../../utils/customStyles';
import { validateUserObject } from '../../utils/validateFunctions';
import { upgradeUserToAdminViaEmail } from '../../api/orgs';
// ----------------------------------------------------------------------

export default function OrgUserList ({ action, setOrgAdmins, list, orgSessions }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25); 
  const [searchText, setSearchText] = useState("")
  const [usersData, setUsersData] = useState(null);

  useEffect(() => {
    const fetchUsersData = async () => {
      const usersData = await Promise.all(list.map(async (user) => {
        const userData = await getUserMetadata(user.userId);
        return {...user, meta: userData.data};
      }));
      setUsersData(usersData);
    };

    fetchUsersData();
  }, [list]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableHead = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'recentSession', label: 'Recent Session', alignRight: false },
    { id: 'totalSessions', label: 'Total Sessions', alignRight: false },
    { id: 'userType', label: 'User Type', alignRight: false },
    { id: 'expired', label: 'Expired', alignRight: false },
    { id: 'upgrade', label: 'Upgrade/Downgrade', alignRight: false },
    { id: 'menu', label: '', alignRight: false },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  // Pull out
  const findMostRecentSession = (user, sessions) => {
    const orgSessions = sessions.filter(session => session.user_id === user);
    if (orgSessions.length === 0) return null;
    return orgSessions.reduce((prev, current) => {
      return (new Date(current.date) > new Date(prev.date)) ? current : prev;
    });
  };
  const filterUsers = (list, sessions, searchText) => {
   return list
    .map(user => {
        const recentSession = findMostRecentSession(user.userId, sessions);
        return { ...user, recentSession };
    })
    .filter(user => user.name.toLowerCase().includes(searchText.toLowerCase()) 
    || user.email.toLowerCase().includes(searchText.toLowerCase()))
    .sort((user1, user2) => {
        if (!user1.recentSession) return 1;
        if (!user2.recentSession) return -1;
        return new Date(user2.recentSession.date) - new Date(user1.recentSession.date);
    });
  };
  
  if (usersData) {
    const sortedUsers = filterUsers(usersData, orgSessions, searchText);

    return (
      <TableContainer sx={{ minWidth: 800 }}>
        <Toolbar sx={{justifyContent: "space-between"}}>
        <TextField
            label="Search by name"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {action}
        </Toolbar>
        <Table>
          <OrgListHead headLabel={tableHead}/>
          <TableBody>
            {sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <OrgUser key={row.userId} user={row} setOrgAdmins={setOrgAdmins} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]} 
          component="div"
          count={sortedUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    );
  } else {
    return (
      <Typography>
        Loading...
      </Typography>
    )
  }
}

// ----------------------------------------------------------------------

function OrgUser ({ user, setOrgAdmins }) {
  const { 
    name, email, userId, orgId, usersData, adminNotes, meta, totalSessions, userType, dateCreated, dateDuration, recentSession 
  } = user;
  
  const [loadingStatus, setLoadingStatus] = useState(false)

  const [color, setColor] = useState('primary')

  const [userData, setUserData] = useState(null)

  const [missingMetadata, setMissingMetadata] = useState(null)

  const [openModal, setOpenModal] = useState(false)
  
  const [open, setOpen] = useState(null);

  useEffect(()=> {
    const missingdata = validateUserObject(meta)
    setMissingMetadata(missingdata)
    setUserData(meta)
  }, [user])

  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };
  
  const expiration = calculateExpiration(dateCreated, dateDuration)
  
  const handleClose = () => {
    setOpen(null)
  };

  const handleUpgrade = async (email, org) => {

    setLoadingStatus(true)

    const response = await upgradeUserToAdminViaEmail(email, org)

    response.status === 200 ? setColor('success')  : setColor('error')
    
    setLoadingStatus(false)

    setOrgAdmins("")
  }

  const handleDowngrade = async (userId, orgId) => {

    setLoadingStatus(true)

    const response = await demoteAdminToUser(userId, orgId)

    setLoadingStatus(false)
    
    response.success ? setColor('success') : setColor("error")
  
    setOrgAdmins("")

  }

  const handleButtonClick = () => {
    if (userType === 'admin') {
      handleDowngrade(userId, orgId);
    } else {
      handleUpgrade(email, orgId);
    }
  };

  const handleMenuItemClick = () => {
    setOpenModal(true);
    setOpen(null)
  };


  let warningClass = 'transparent';
  if (missingMetadata && missingMetadata.length > 0) {
    const hasHeightOrWeight = missingMetadata.includes("Height" || "Weight")
    // const hasHeightOrWeight = missingdata.some(key => key === 'Height' || key === 'Weight');
    warningClass = hasHeightOrWeight ? 'error.main' : 'error.light';
  }
  if(userData) {
    return (
      <TableRow tabIndex={-1}>
        <TableCell component="th" scope="row">
          <Stack direction="row" alignItems="center" spacing={1}>
            <CustomTooltip 
              title={ 
                <>
                  <Typography variant='caption'>Copy User ID</Typography>
                  <IconButton>
                    <ContentCopy onClick={()=> handleCopyClick(userId)}/>
                  </IconButton>
                </>
              }
            >
              <AssignmentIndOutlined />
            </CustomTooltip>
  
            <Stack>
              <Typography noWrap fontWeight={700}>
                {name}
              </Typography>
              <Typography>Email: {email}</Typography>
              <Typography>Created On: {formatDate(dateCreated)}</Typography>
            </Stack>
  
            <IconButton onClick={() => handleShowJson(usersData)}>
              <DataObject/>
            </IconButton>
  
            {adminNotes && 
              <CustomTooltip 
                title={
                  <Typography variant='caption'>
                    {adminNotes}
                  </Typography>
                }
              >
                <InfoOutlined/>
              </CustomTooltip>
            }
  
            {missingMetadata && missingMetadata.length > 0 && 
              <CustomTooltip 
                title={
                  <Typography variant="caption">
                    Missing Fields are : {missingMetadata}
                  </Typography>
                }
              >
                <WarningOutlined sx={{color: warningClass}}/>
              </CustomTooltip>
            }
            </Stack>
  
        </TableCell>
  
        <TableCell>
          <Typography>{recentSession ? formatDate(recentSession.date) : "N/A"}</Typography>
        </TableCell>
  
        <TableCell>
          <Typography>{totalSessions}</Typography>
        </TableCell>
  
        <TableCell>
          <Typography>{userType}</Typography>
        </TableCell>
  
        <TableCell>
                      
            <Stack direction="row" spacing={1}>
              {expiration.differenceInDays < 0  ? 
                  <Cancel sx={{color: "error.main"}}/> 
                : 
                expiration.differenceInDays  < 30 ? 
                  <ReportProblem sx={{color: "warning.main"}}/> 
                : 
                  <CheckCircle sx={{color: "success.dark"}}/>}
              <Typography>
              {expiration.differenceInDays  < 0  ? `Expired` : expiration.differenceInDays  < 10 ? `${expiration.differenceInDays } days left` : `${expiration.differenceInDays } days`}
              </Typography>
          </Stack>
        </TableCell>
  
        <TableCell>
          <LoadingButton
            variant='outlined'
            loading={loadingStatus}
            color={color}
            onClick={handleButtonClick}
          >
            {userType === 'admin' ? 'Downgrade' : 'Upgrade'}
          </LoadingButton>
        </TableCell>
  
        <TableCell>
          <IconButton onClick={handleClick}>
            <MoreVert/>
          </IconButton>
        </TableCell>
  
        <EditMetadata 
          openModal={openModal} 
          handleCloseModal={()=> setOpenModal(false)} 
          userData={userData}
        />
  
        <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={()=> handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick()}>Edit Metadata</MenuItem>
      </Popover>
      </TableRow>
    
    );
  }
}
