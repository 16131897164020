import { filter } from 'lodash';
import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  Button,
  TableContainer,
  TablePagination,
} from '@mui/material';
// @mui-icons
import { ContentCopy } from '@mui/icons-material';
// components
// sections
import { SessionsListHead, SessionsListToolbar } from '../sections/sessions';
// API Calls
import { listAllOrgSessions } from "../api/sessions";
// Utils
import { formatDate } from '../utils/formatFunctions'
// ----------------------------------------------------------------------

const tableHead = [
  { id: 'name', label: 'Session Number', alignRight: false },
  { id: 'athleteId', label: 'Athlete Id', alignRight: false },
  { id: 'sessionId', label: 'Session Id', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_session) => _session.session_id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const handleCopyClick = (sessionId) => {
  navigator.clipboard.writeText(sessionId)
    .then(() => {
     console.log(sessionId)
    })
    .catch((err) => {
      console.error("Unable to copy text: ", err);
    });
};

export default function Organizations () {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [sessionData, setSessionData] = useState(null)

  useEffect(()=> {

      const fetchSessionData = async() => {
          try {
              const data = await listAllOrgSessions()
              setSessionData(data.data)
          } catch(err) {
              console.log(err)
          }
      }

      fetchSessionData()

  }, [])


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sessionData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (sessionData) {

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sessionData.length) : 0;
  
    const filteredSessions = applySortFilter(sessionData, getComparator(order, orderBy), filterName);
  
    const isNotFound = !filteredSessions.length && !!filterName;
  
    return (
      <>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              All Sessions
            </Typography>

          </Stack>
  
          <Card>
            <SessionsListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
  
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <SessionsListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={sessionData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, session_number, user_id, session_id, date } = row;
                      const selectedSession = selected.indexOf(session_number) !== -1;
  
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedSession}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedSession} onChange={(event) => handleClick(event, session_number)} />
                          </TableCell>
  
                          <TableCell component="th" scope="row" padding="none">
                            <Link 
                              to={`/dashboard/session/${session_number}`}  
                              style={{textDecoration: "none"}}
                              state= {{ 
                                sessionId: session_id,
                              }}
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    Session #{session_number}
                                  </Typography>
                              </Stack>
                            </Link>
                          </TableCell>
  
                          <TableCell align="left">{user_id}</TableCell>

                          <TableCell align="left">
                            {session_id}
                            <Button
                              onClick={()=> handleCopyClick(session_id)}
                              endIcon={<ContentCopy/>}
                            />
                          </TableCell>

                          <TableCell align="left">{formatDate(date)}</TableCell>
                          
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>

                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
  
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
  
                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
  
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sessionData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
  
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem sx={{ color: 'error.main' }}>

              Edit
          </MenuItem>
        </Popover>
      </>
    );
  }
}
