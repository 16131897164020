import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import json from '../assets/json.png'
// @mui
import { Grid, Container, Typography, Stack, Divider, Box } from '@mui/material';
import { AssignmentIndOutlined } from "@mui/icons-material";
// API Calls
import { findSessionById } from "../api/sessions";
// Utils
import { CustomTooltip } from "../utils/customStyles";
import { handleShowJson } from "../utils/jsonFunction";
import { formatDate } from "../utils/formatFunctions";
// ----------------------------------------------------------------------

export default function Session () {
  const [sessionData, setSessionData] = useState(null)
  const location = useLocation();
  const sessionId = location.state.sessionId

  useEffect(()=> {

    const fetchSessionData = async(id) => {
        try {
            const data = await findSessionById(id)
            console.log(data)
            setSessionData(data.data[0])
        } catch(err) {
            console.log(err)
        }
    }

    fetchSessionData(sessionId)
  }, [sessionId])



  if (sessionData) {

      const {
        performer: {
          performer_email,
          performer_name
        },
        selector: {
          selector_first_name,
          selector_last_name,
          selector_email
        },
        session_id,
        is_valid,
        session_number,
        session_description,
        start_timestamp,
        stop_timestamp,
        date,
        user_id,
        org_id,
        session_metrics: { duration, reps },
        activity_list: [{
          activity: { activity_name },
          device_list: [{
            device_name,
            device_type,
            placement,
            firmware_version
          }]
        }],
        client_metadata: {
          platform,
          version,
          data_version,
          timestamp
        }
      } = sessionData;

      return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" mb={2}>Session #{session_number}</Typography>

                    <Typography variant="body1" mb={2}>Performed By {performer_name}</Typography>

                    <Stack         
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        mb={2}
                    >
                        <Stack direction="row" spacing={1}>
                            <Typography fontWeight={700}>Org ID</Typography>
                            <CustomTooltip 
                                arrow 
                                title={
                                    <Stack direction={"row"}>
                                        <Typography>{org_id}</Typography>

                                        {/* <ContentCopy onClick={()=> handleCopyClick(orgId)}/> */}
                                    </Stack>
                                }
                            >
                                <AssignmentIndOutlined />
                            </CustomTooltip>
                        </Stack>

                        <Stack direction={"row"} spacing={1}>
                            <Typography fontWeight={700}>View Session Data</Typography>
                            <Box 
                                onClick={()=>handleShowJson(sessionData, window)}
                                component="img"
                                sx={{ width: 20, height: 20, cursor: "pointers" }}
                                src={json}
                            />
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            <Typography fontWeight={700}>User ID</Typography>
                            <CustomTooltip 
                                arrow 
                                title={
                                    <Stack direction={"row"}>
                                        <Typography>{user_id}</Typography>

                                        {/* <ContentCopy onClick={()=> handleCopyClick(orgId)}/> */}
                                    </Stack>
                                }
                            >
                                <AssignmentIndOutlined />
                            </CustomTooltip>
                        </Stack>

                        <Typography>
                            Date completed: {formatDate(date)}
                        </Typography>


                        <Typography>
                             {is_valid ? "Valid Session" : "Invalid Session"}
                        </Typography>
                    </Stack>
                    
                    <Divider/>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">
                        Performer Info
                    </Typography>
                    <Typography variant="body1">
                        Performer Name: {performer_name}
                    </Typography>
                    <Typography variant="body1">
                        Performer Email: {performer_email}
                    </Typography>
                    <Typography variant="body1">
                        Fix
                        Performer User Id: {performer_email}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">
                        Selector Info
                    </Typography>
                    <Typography variant="body1">
                        Selector First Name: {selector_first_name}
                    </Typography>
                    <Typography variant="body1">
                        Selector Last Name: {selector_last_name}
                    </Typography>
                    <Typography variant="body1">
                        Selector Email: {selector_email}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">
                        Session Meta Data
                    </Typography>
                    <Typography variant="body1">
                        Session ID: {session_id}
                    </Typography>
                    <Typography variant="body1">
                        Session Number: {session_number}
                    </Typography>
                    <Typography variant="body1">
                        Session Description: {session_description || 'N/A'}
                    </Typography>
                    <Typography variant="body1">
                        Start Timestamp: {start_timestamp}
                    </Typography>
                    <Typography variant="body1">
                        Stop Timestamp: {stop_timestamp || 'N/A'}
                    </Typography>
                    <Typography variant="body1">
                        Duration: {duration} seconds
                    </Typography>
                    <Typography variant="body1">
                        Reps: {reps}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">
                        Activity Info
                    </Typography>
                    <Typography variant="body1">
                        Activity Name: {activity_name}
                    </Typography>
                    <Typography variant="body1">
                        Device Name: {device_name}
                    </Typography>
                    <Typography variant="body1">
                        Device Type: {device_type}
                    </Typography>
                    <Typography variant="body1">
                        Placement: {placement}
                    </Typography>
                    <Typography variant="body1">
                        Firmware Version: {firmware_version}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">
                        Client Meta Data
                    </Typography>
                    <Typography variant="body1">
                        Platform: {platform}
                    </Typography>
                    <Typography variant="body1">
                        Version: {version}
                    </Typography>
                    <Typography variant="body1">
                        Data Version: {data_version}
                    </Typography>
                    <Typography variant="body1">
                        Timestamp: {timestamp}
                    </Typography>
                </Grid>

            </Grid>
        </Container>
    );
  }
}