import { calculateExpiration } from "./formatFunctions";

export const getTrialOrgs = (orgData) => {
    return orgData.filter(org => org.org_type === "trial");
};
  
export const getActiveOrgs = (orgData) => {
    return orgData.filter((org) => {
      const remainingMembershipTime = calculateExpiration(org.date_created, org.date_duration);
      return!remainingMembershipTime.expired;
    });
};
  
export const getOrgsExpiringInAMonthAndSortedByDaysLeft = (orgData) => {
    const orgsExpiringInAMonth = orgData.filter((org) => {
      const remainingMembershipTime = calculateExpiration(org.date_created, org.date_duration);
  
      if (remainingMembershipTime.differenceInDays < 30 && remainingMembershipTime.differenceInDays > 0) {
        org["days_left"] = remainingMembershipTime.differenceInDays;
        return org;
      }
    });
  
    return orgsExpiringInAMonth.sort((a, b) => a.days_left - b.days_left);
  };