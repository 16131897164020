export function validateUserObject(userObj) {
    const missingKeys = [];
    let message = "";
    if(!userObj){
      missingKeys.push("User Data is Missing!");
    }
    if (!userObj.user_metadata) {
        missingKeys.push("No Meta data");
    } else {
        if (!userObj.user_metadata.dob) missingKeys.push("Date Of Birth");
        if (!userObj.user_metadata.height) missingKeys.push("Height");
        else {
            if (!userObj.user_metadata.height.value) missingKeys.push("Height Value");
            if (!userObj.user_metadata.height.unit) missingKeys.push("Height Unit");
        }
        if (!userObj.user_metadata.weight) missingKeys.push("Weight");
        else {
            if (!userObj.user_metadata.weight.value) missingKeys.push("Weight Value");
            if (!userObj.user_metadata.weight.unit) missingKeys.push("Weight Unit");
        }
    }
    if (missingKeys.length > 0 ) {
 
        if (missingKeys.length > 0) {
          message += `Missing keys: ${missingKeys.join(", ")},\n`;
        }
      } 
    
    return message;
  }


export function validateOrganizationObject(obj) {
    const expectedKeys = [
        "version",
        "date_duration",
        "organization_id",
        "admin_count",
        "date_updated",
        "user_count",
        "s3_logo_url",
        "download_metadata",
        "date_created",
        "name",
        "token_api",
        "token_sdk",
        "admin_max",
        "pk_id",
        "download_raw",
        "expired",
        "user_max",
        "notes",
        "org_type",
        "timezone",
        "sk_id",
        "menu_id",
        "download_analyzed",
    ];

    const expectedDataTypes = {
        "version": "string",
        "date_duration": "number",
        "organization_id": "string",
        "admin_count": "number",
        "date_updated": "string",
        "user_count": "number",
        "s3_logo_url": "string",
        "download_metadata": "boolean",
        "date_created": "string",
        "name": "string",
        "token_api": "array",
        "token_sdk": "array",
        "admin_max": "number",
        "menu_id": "string",
        "pk_id": "string",
        "download_raw": "boolean",
        "expired": "boolean",
        "user_max": "number",
        "notes": "string",
        "org_type": "string",
        "timezone": "string",
        "sk_id": "string",
        "download_analyzed": "boolean",
    };

    const missingKeys = expectedKeys.filter(key => !(key in obj));
    const unexpectedKeys = Object.keys(obj).filter(key => !expectedKeys.includes(key));
    const invalidTypes = Object.keys(obj).filter(key => {
        const dataType = Array.isArray(obj[key]) ? "array" : typeof obj[key];
        return dataType !== expectedDataTypes[key];
    });


    if (unexpectedKeys.length > 0 || invalidTypes.length > 0 || missingKeys.length > 0) {
        let message = '';
        if (unexpectedKeys.length > 0) {
            message += `Unexpected key: ${unexpectedKeys.join(", ")},\n`;
        }
        if (invalidTypes.length > 0) {
            message += `Invalid data type for: ${invalidTypes.join(", ")},\n`;
        }
        if (missingKeys.length > 0) {
            message +=  `Missing keys: ${missingKeys.join(", ")},\n`;
        }
        return { valid: false, message };
    } else {
        return { valid: true, message: "Object validation successful" };
    }
}



export function validateOrgUserProfile(obj){
    const expectedKeys = [
    'gsi_u_id',
    'version',
    'date_duration',
    'organization_id',
    'session_history_metadata',
    'date_updated',
    'epoch_time',
    'gsi_o_id',
    'date_created',
    'email',
    'name',
    'admin_settings',
    'pk_id',
    'session_list',
    'device_list',
    'user_id',
    'expired',
    'last_name',
    'first_name',
    'push_list',
    'sk_id',
    'is_active',
    'type'
    ];

    const expectedDataTypes = {
        "gsi_u_id": "string",
        "version": "string",
        "date_duration": "number",
        "organization_id": "string",
        "session_history_metadata": "object",
        "date_updated": "string",
        "epoch_time": "number",
        "gsi_o_id": "string",
        "date_created": "string",
        "email": "string",
        "name": "string",
        "admin_settings": "object",
        "pk_id": "string",
        "session_list": "array",
        "device_list": "array",
        "user_id": "string",
        "expired": "boolean",
        "last_name": "string",
        "first_name": "string",
        "push_list": "array",
        "sk_id": "string",
        "is_active": "boolean",
        "type": "string"
      };

    const missingKeys = expectedKeys.filter(key => !(key in obj));

    const unexpectedKeys = Object.keys(obj).filter(key => !expectedKeys.includes(key));

    const invalidTypes = Object.keys(obj).filter((key) => {
        const dataType = Array.isArray(obj[key]) ? "array" : typeof obj[key];
        return dataType !== expectedDataTypes[key];
    });

    if (unexpectedKeys.length > 0 || invalidTypes.length > 0 || missingKeys.length > 0) {
        let message = '';
        if (unexpectedKeys.length > 0) {
            message += `Unexpected key: ${unexpectedKeys.join(", ")},\n`;
        }
        if (invalidTypes.length > 0) {
            message += `Invalid data type for: ${invalidTypes.join(", ")},\n`;
        }
        if (missingKeys.length > 0) {
            message +=  `Missing keys: ${missingKeys.join(", ")},\n`;
        }
        return { valid: false, message };
    } else {
        return { valid: true, message: "Object validation successful" };
    }
}