import React, { useEffect, useState } from 'react';
// @mui
import {
  Modal, 
  Divider, 
  IconButton,
  Grid,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Card,
  CardHeader,
  Typography,
  TextField
} from '@mui/material/';
// @mui-icons
import { Close } from '@mui/icons-material';
// Apis
import { updateUserMetadata } from '../../api/users';
// Utils
import { toISOLocal } from '../../utils/formatFunctions';
import { commonModalStyle } from '../../utils/customStyles';
import { months, days, years, lbs, kgs, inches, cms } from '../../utils/metadataVariables'
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function EditMetadata ({
  userData,
  openModal,
  handleCloseModal
}){
 const [loadingStatus, setLoadingStatus] = useState(false)
 const [errorMessage, setErrorMessage] = useState("")
 const [formData, setFormData] = useState({})

 useEffect(()=> {
    console.log(userData)
    const { dob, height, weight, dominant_arm, dominant_leg } = userData.user_metadata? userData.user_metadata : {}

    let year, month, day;

    if (dob && dob !== "null") {
      [year, month, day] = dob.split("-");
        month = month.padStart(2, '0'); 
        day = day.padStart(2, '0'); 
    } else {
      year = null;
      month = null;
      day = null;
    }

    setFormData({
        year,
        month,
        day,
        first_name: userData.first_name,
        last_name: userData.last_name,
        heightMetric: height ? height.unit : "in",
        weightMetric: weight ? weight.unit : "lb",
        height: height ? height.value : null,
        weight: weight ? weight.value : null,
        dominant_arm,
        dominant_leg,
        user_id: userData.user_id,
    })
 },[userData])

 const { year, month, day, height, heightMetric, weight, weightMetric, dominant_arm, dominant_leg, first_name, last_name} = formData
 const heightArray = heightMetric === "in" ? inches : cms;
 const weightArray = weightMetric === "lb" ? lbs : kgs;

 const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    if(name.includes("Metric")) {
        const unit = name.slice(0,6)
        setFormData({
            ...formData,
            [unit]: null,
            [name]: value,

        })
    } else {
        setFormData({
            ...formData,
            [name]: value,
        });
    }
  };

 const handleAthleteMetadataUpdate = async() => {
    setLoadingStatus(true)
    // Add Date Updated
    const date = new Date()
    const formattedDate = toISOLocal(date)
    const trimmedFirstName = first_name.trim();
    const trimmedLastName = last_name.trim();
    const full_name = trimmedFirstName.concat(` ${trimmedLastName}`);

    const updatedForm = {...formData, dateUpdated: formattedDate, full_name, first_name: trimmedFirstName, last_name: trimmedLastName}

    const allFilled = Object.values(updatedForm).every(value => Boolean(value));
    console.log(updatedForm)
    if (allFilled) { 
        const response = await updateUserMetadata(updatedForm)
        console.log(response)
        response.success ? handleCloseModal() : setErrorMessage("Error editing meta data")
    } else {
        setErrorMessage("Please Fill Out All Fields!")
    }
    setLoadingStatus(false)
 }


  return (
    <Modal
      open={openModal}
      hideBackdrop={false}
      onClose={handleCloseModal}
    >
      <Card sx={commonModalStyle}>
          <CardHeader 
              title={"Edit Athlete Metadata"} 
              action={
                  <IconButton onClick={handleCloseModal}>
                      <Close/>
                  </IconButton>
              }
          />

        <Divider/>

        <Grid container spacing={1} mt={2}>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    value={first_name}
                    name="first_name"
                    label="First Name"
                    onChange={handleFormDataChange}
                />
            </Grid>
            
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    value={last_name}
                    name="last_name"
                    label="Last Name"
                    onChange={handleFormDataChange}
                />
            </Grid>

            <Grid item xs={4}>

                <FormControl fullWidth required>
                    <InputLabel id="month"> MM </InputLabel>
                    <Select
                        labelId="month"
                        label="MM"
                        name="month"
                        variant="outlined" 
                        value={month}
                        onChange={handleFormDataChange}
                    >
                        {months.map((month, index) => (
                            <MenuItem key={index} value={month}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth required>
                    <InputLabel id="day">DD</InputLabel>
                    <Select                
                        variant="outlined" 
                        labelId="day"
                        label="DD"
                        name="day"
                        value={day}
                        onChange={handleFormDataChange}
                    >
                    {days.map((day, index) => (
                        <MenuItem key={index} value={day}>
                            {day}
                        </MenuItem>
                    ))}
                    </Select>                
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth required>
                    <InputLabel id="year">YYYY</InputLabel>
                    <Select       
                        labelId="year"
                        label="YYYY"
                        name="year"
                        variant="outlined" 
                        value={year}
                        onChange={handleFormDataChange}
                    >
                    {years.map((year, index) => (
                        <MenuItem key={index} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel>Weight</InputLabel>
                    <Select label="Weight" value={weight} name="weight" onChange={handleFormDataChange}>
                        {weightArray.map((weight, index)=> (
                            <MenuItem key={index} value={weight}>
                                {weight}
                            </MenuItem>
                        ))} 
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Metric</InputLabel>
                    <Select label="Metric" value={weightMetric} name="weightMetric" onChange={handleFormDataChange}>
                        <MenuItem value={"kg"}>kg</MenuItem>
                        <MenuItem value={"lb"}>lb</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel>Height</InputLabel>
                    <Select label="Height" name="height" value={height} onChange={handleFormDataChange}>
                        {heightArray.map((height, index)=> (
                            <MenuItem key={index} value={height}>
                                {height}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Metric</InputLabel>
                    <Select label="Height Metric" name="heightMetric" value={heightMetric} onChange={handleFormDataChange}>
                        <MenuItem value={"in"}>in</MenuItem>
                        <MenuItem value={"cm"}>cm</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel>Dominant Leg</InputLabel>
                    <Select label="Dominant Leg" value={dominant_leg} name="dominant_leg" onChange={handleFormDataChange}>
                        <MenuItem value={"right"}>Right</MenuItem>
                        <MenuItem value={"left"}>Left</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
    
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel>Dominant Arm</InputLabel>
                    <Select label="Dominant Arm" value={dominant_arm} name="dominant_arm" onChange={handleFormDataChange}>
                        <MenuItem value={"right"}>Right</MenuItem>
                        <MenuItem value={"left"}>Left</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <Typography sx={{color: "error.main"}}>{errorMessage}</Typography>
                <LoadingButton
                    variant="contained"
                    loading={loadingStatus}
                    onClick={(e)=> handleAthleteMetadataUpdate(e)}
                    sx={{width: "50%"}}
                >
                    Update Metadata
                </LoadingButton>
            </Grid>
        </Grid>
      </Card>
    </Modal>
  )
}