import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Typography,
  InputAdornment,
  IconButton,
  Stack,
  Container,
  TextField,
} from "@mui/material";
// @mui-icons
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  MailOutline,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
// APIS
import { authenticateUser } from "../api/auth";

export default function Login() {
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [focusOne, setFocusOne] = useState(false);
  const [focusTwo, setFocusTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState({
    error: false,
    errorMessage: ""
  })    
  const navigate = useNavigate();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async(event) => {
    event.preventDefault(); 

    if (!emailValue || !passwordValue) {
        setErrorState({ error: true, errorMessage: "Enter an email and password." });
        return;
    }
  
    setLoading(true);

    const userData = {
      email: emailValue,
      password: passwordValue
    }

    const response = await authenticateUser(userData)
    const { valid_credentials, user_type } = response
    
    if (valid_credentials) {
      localStorage.setItem("auth", true);
      localStorage.setItem("user_type", user_type);
      navigate('/dashboard/orgs', { replace: true });
    } else {
      setLoading(false); 
      setErrorState({error: true, errorMessage: "Incorrect Information. Please try again!"})
    }
  };

  return (
    <Container maxWidth="xl">

      <Stack 
        component="form"
        height={"100vh"} 
        margin="auto"
        sx={{ width: "65%"}} 
        alignItems={"center"} 
        justifyContent={"center"}
        gap={2}
        spacing={3}
        onSubmit={handleLogin}
      >

        <Typography variant="h4">Login</Typography>

        <TextField
            focused={focusOne}
            fullWidth
            id="field"
            type="text"
            autoComplete="username"
            value={emailValue}
            error={errorState.error}
            variant="outlined"
            InputProps={{
                startAdornment: focusOne && (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
            onFocus={() => setFocusOne(true)}
            label="Email"
            onChange={(e) => setEmailValue(e.target.value)}
        />

        <TextField
            id="password"
            focused={focusTwo}
            type={showPassword ? "text" : "password"}
            fullWidth
            error={errorState.error}
            autoComplete="current-password"
            label="Password"
            value={passwordValue}
            InputProps={{
                startAdornment: focusTwo && (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </IconButton>
                    </InputAdornment>
                ),
            }}
            onFocus={() => setFocusTwo(true)}
            onChange={(e) => setPasswordValue(e.target.value)}
        />

        <LoadingButton
            fullWidth
            id="login-button"
            type="submit"
            variant="contained"
            disableRipple
            loading={loading}
        >
            Login
        </LoadingButton>

        <Typography color={"error.main"}>{errorState.errorMessage}</Typography>
      </Stack>
    </Container>
  );
}