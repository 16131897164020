export const days = [
    "01","02","03","04","05","06",
    "07","08","09","10","11","12",
    "13","14","15","16","17","18",
    "19","20","21","22","23","24",
    "25","26","27","28"
]

export const months = 
    [ 
        "01","02","03","04","05",
        "06","07","08","09","10",
        "11","12"
]

export const years = Array.from(Array(new Date().getFullYear() - 1949), (_, i) => (i + 1950).toString()).reverse()

export const cms = Array.from({ length: 123 }, (_, index) => 122 + index);
export const inches = Array.from({ length: 37 }, (_, index) => 48 + index);

export const lbs = Array.from({ length: 321 }, (_, index) => 80 + index);
export const kgs = Array.from({ length: 147 }, (_, index) => 36 + index);
