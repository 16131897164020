import React from 'react';
// @mui
import { TableCell, TableRow, Typography, Table, TableContainer, TableBody, Chip, IconButton, Stack, TablePagination } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
// Components
import { OrgListHead } from '../orgs';
// Utils
import { handleCopyClick } from '../../utils/copyFunction';
import { formatDate } from '../../utils/formatFunctions';
// ----------------------------------------------------------------------
const tableHead = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'product', label: 'Product', alignRight: false },
  { id: 'firmware', label: 'Firmware', alignRight: false },
];

export default function UserDeviceList ({ list, ...other }) {
  return (
    <TableContainer>
      <Table>
        <OrgListHead headLabel={tableHead}/>
        <TableBody>
          {list.map((row, index) => (
            <UserInfo key={row.userId} info={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// ----------------------------------------------------------------------
function UserInfo ({ info }) {
  const {custom_name, date_created, device_name, firmware_version, id, last_edit, placement, product_type } = info;
  console.log(info)
  return (
    <TableRow tabIndex={-1}>
      <TableCell component="th" scope="row">
        <Stack direction="row">
          <Typography>
            <b>{custom_name ? custom_name: device_name}</b> 
            <IconButton>
              <ContentCopy onClick={()=> handleCopyClick(id)}/>
            </IconButton>
            <br/>
            Created On: {formatDate(date_created.slice(0,10))}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography>
          <b>{product_type ? product_type : "N/A"}</b>
          <br/>
          {placement ? placement : "N/A"}
        </Typography>
      </TableCell>

      <TableCell>
        <Chip
          label={firmware_version ? firmware_version : "N/A"}
          variant="outlined"
        />
      </TableCell>
    </TableRow>
  );
}