import React, { useState } from 'react';
import dayjs from 'dayjs';
// @mui
import {
  Box, Stack, Modal, Divider, Typography, IconButton, TextField, 
  FormControl, FormControlLabel, FormLabel, Button, Radio, RadioGroup,
  Grid, Switch, Select, MenuItem, InputLabel
} from '@mui/material/';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// @mui-icons
import { Close } from '@mui/icons-material';
// APIs
import { updateOrganization } from '../../api/orgs'; 
// Utils
import { toISOLocal, formatDate, calculateExpirationDate, calculateDaysDifference } from '../../utils/formatFunctions';
import { commonModalStyle } from '../../utils/customStyles';
// ----------------------------------------------------------------------

export default function EditOrg ({
  orgId,
  orgData,
  orgMenus,
  openModal,
  snackbarState,
  setSnackbarState,
  handleCloseModal
}){
 const [errorMessage, setErrorMessage] = useState("")
 const [organizationNameHelperText, setOrganizationNameHelperText] = useState("")
 const [organizationName, setOrganizationName] = useState(orgData.name)
 const [expirationDate, setExpirationDate] = useState(formatDate(calculateExpirationDate(orgData.date_created, orgData.date_duration)))
 const [organizationUserCount, setOrganizationUserCount] = useState(orgData.user_max)
 const [organizationAdminCount, setOrganizationAdminCount] = useState(orgData.admin_max)
 const [notes, setNotes] = useState(orgData.notes)
 const [expired, setExpired] = useState(orgData.expired)
 const [downloadAnalyzed, setDownloadAnalyzed] = useState(orgData.download_analyzed ? orgData.download_analyzed : false)
 const [downloadRaw, setDownloadRaw] = useState(orgData.download_raw ? orgData.download_raw : false)
 const [downloadMeta, setDownloadMeta] = useState(orgData.download_metadata ? orgData.download_metadata : false)
 const [metricSelection, setMetricSelection] = useState(orgData.menu_id ? orgData.menu_id : "No Change");
 
 const [orgTypeSelection, setOrgTypeSelection] = useState(orgData.org_type);

 function isInteger(value) {
  if (value === "" || value === "-") {
    return true; // Empty or just a negative symbol, consider it valid
  }
  return /^-?\d+$/.test(value) && Number.isInteger(parseInt(value, 10));
}

 const handleOrgTypeChange = (event) => {
  setOrgTypeSelection(event.target.value);
};

const handleOrganizationNameChange = (e) => {
  const orgName = e.target.value
  setOrganizationName(orgName)

  const trimmedOrgName = orgName.trim()
  trimmedOrgName.length <= 2 ? 
  setOrganizationNameHelperText("Please enter a minimum of 3 characters.") 
  : setOrganizationNameHelperText("")
}

const handleNumericalFieldChange = (event, setter) => {
  const val = event.target.value;
  if (isInteger(val)){
    setter((val === "" || val === "-") ? 0 : parseInt(val));
  }
};

const handleSuccessfulOrgUpdate = () => {
    setSnackbarState({ 
      ...snackbarState, 
      open: true, 
      text: "The Organization has been successfully updated!" 
    });
    handleCloseModal()
}

const handleMetricMenuChange = (e) => {
  setMetricSelection(e.target.value)
}

const handleOrgUpdate = async() => {
  const date = new Date()
  const formattedDate = toISOLocal(date)
  const selectedMetricJson = metricSelection === "No Change" ?  null : metricSelection;

  const reformattedExpirationDate = dayjs(expirationDate).format("MM/DD/YYYY")

  const convertedExpirationDate = 
    calculateDaysDifference(orgData.date_created.slice(0,10), reformattedExpirationDate)

  const updatedData = {
    organizationName, 
    adminMax: organizationAdminCount, 
    userMax: organizationUserCount, 
    dateDuration: convertedExpirationDate, 
    formattedDate, 
    notes,
    downloadAnalyzed,
    downloadRaw,
    downloadMeta,
    orgType: orgTypeSelection,
    expired,
    menu_id: selectedMetricJson
  }

  console.log(updatedData)
  const response = await updateOrganization(orgId, updatedData)
  response.success === true ? handleSuccessfulOrgUpdate() : setErrorMessage("An error occurred updating the Org!")
}



const handleSubmit = async (e) => {
  if (!organizationName) {
    setErrorMessage("Please Enter an Organization Name!")
  } else if (organizationName.length < 3){
    setErrorMessage("Please enter 3 characters")
  } else {
    handleOrgUpdate()
  }
}

  return (
    <Modal
      open={openModal}
      hideBackdrop={false}
      onClose={handleCloseModal}
    >
        <Box gap={2} sx={commonModalStyle}>

          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography id="modal-modal-title" variant="h4" whiteSpace="nowrap">
                Edit Org
              </Typography>

              <IconButton aria-label="close" onClick={handleCloseModal}>
                  <Close/>
              </IconButton>
          </Stack>

          <Divider/>

          <Grid spacing={3} container component="form">

            <Grid item xs={6}>

              <Stack spacing={2} m={2}>

                <TextField 
                  required
                  label="Organization Name" 
                  value={organizationName} 
                  onChange={handleOrganizationNameChange}
                  helperText={organizationNameHelperText}
                />

                <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>

                  <TextField 
                    label="Max User Count" 
                    fullWidth
                    value={organizationUserCount} 
                    onChange={(event) => handleNumericalFieldChange(event, setOrganizationUserCount)}
                  />

                  <TextField 
                    label="Max Admin Count" 
                    fullWidth
                    value={organizationAdminCount} 
                    onChange={(event) => handleNumericalFieldChange(event, setOrganizationAdminCount)}
                  />

                </Stack>

                <DatePicker label="Expiration Date" value={dayjs(expirationDate)} onChange={(newValue) => setExpirationDate(dayjs(newValue))} />

                <TextField label="Notes" value={notes} onChange={((e) => {setNotes(e.target.value)})} /> 

              </Stack>

            </Grid>

            <Grid item xs={6}>

              <Stack spacing={2} m={2}>

              <FormControl>
                <InputLabel>Metric Menu ID</InputLabel>
                <Select
                  value={metricSelection}
                  onChange={handleMetricMenuChange}
                  fullWidth
                  label="Metric Menu ID"
                >
                  {orgMenus.map((menu)=> (
                    <MenuItem value={menu.pk_id}>{menu.display_name}</MenuItem>
                  ))}
                  <MenuItem value="No Change">No Change</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                  <FormLabel>Type of Org</FormLabel>
                  <RadioGroup  value={orgTypeSelection} onChange={handleOrgTypeChange}>
                    <FormControlLabel value="trial"  control={<Radio />} label="Trial" />
                    <FormControlLabel value="member" control={<Radio />} label="Member" />
                    <FormControlLabel value="internal" control={<Radio disabled={true}/>} label="Internal" />
                  </RadioGroup>
                </FormControl>

                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>Download Analyzed</FormLabel>
                      <FormControlLabel control={<Switch checked={downloadAnalyzed} onChange={(e) => { setDownloadAnalyzed(e.target.checked)}}/>} />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Download Raw</FormLabel>
                      <FormControlLabel control={<Switch checked={downloadRaw} onChange={(e) => { setDownloadRaw(e.target.checked)}}/>} />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Download Meta</FormLabel>
                      <FormControlLabel control={<Switch checked={downloadMeta} onChange={(e) => { setDownloadMeta(e.target.checked)}}/>} />
                  </FormControl>
                </Stack>

 
                <FormControl>
                  <FormLabel>Expired</FormLabel>
                  <FormControlLabel control={<Switch checked={expired} onChange={(e) => { setExpired(e.target.checked)}}/>} />
                </FormControl>
   
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={(e)=> handleSubmit(e)}
                sx={{width: "50%", alignSelf: 'center'}}
              >
                Update Org
              </Button>
            </Grid>
          </Grid>
        </Box>
    </Modal>
  )
}