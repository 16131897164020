// Images
import armsleeve from '../assets/armsleeve.png'
import kneesleeve from '../assets/kneesleeve.png'
import ffp from '../assets/ffp.png'
import mask from '../assets/mask.png'
import sock from '../assets/sock.png'
import { Box } from '@mui/material'

export const productIconMap = {
    "P-ARM_SLEEVE": <Box component="img" src={armsleeve}  width={28}/>,
    "P-SURFACE_FABRIC": <Box component="img" src={ffp}  width={28}/>,
    "P-KNEE": <Box component="img" src={kneesleeve} width={28}/>,
    "P-FACEMASK": <Box component="img" src={mask}  width={28}/>,
    "P-SOCK": <Box component="img" src={sock}  width={28}/>,
  }
