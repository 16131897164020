// ----------------------------------------------------------------------
import { LanguageOutlined, FormatListBulletedOutlined, PeopleAltOutlined, LegendToggleOutlined, SettingsOutlined, AccountCircleOutlined } from '@mui/icons-material';

const generalConfig = [
  {
    title: 'All Organizations',
    path: '/dashboard/orgs',
    icon: <LanguageOutlined/>,
  },
  {
    title: 'All Users',
    path: '/dashboard/users',
    icon: <FormatListBulletedOutlined/>,
  },
  {
    title: 'Insights',
    path: '/dashboard/stats',
    icon: <PeopleAltOutlined/>,
  },
  // {
  //   title: 'History',
  //   path: '/dashboard/history',
  //   icon: <LegendToggleOutlined/>,
  // },
];


const personalConfig = [
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: <SettingsOutlined/>,
  },
  {
    title: 'My Account',
    path: '/dashboard/account',
    icon: <AccountCircleOutlined/>,
  },
];


export { generalConfig, personalConfig };
