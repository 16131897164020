import { Outlet } from 'react-router-dom';
// @mui
import { Grid, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// Assets
import background from '../assets/background.png'
import nextiles from "../assets/nextilessvg.svg"
// ----------------------------------------------------------------------

export default function AuthLayout () {

  const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
  });

  return (
    <StyledRoot>
      
      <Grid container columns={20}>

          <Grid item xs={0} sm={0} md={11}
              sx={{ 
                  backgroundImage: `url(${background})`,  
                  backgroundRepeat: 'no-repeat', 
                  backgroundSize: 'cover',
                  display: { xs: "none", sm: "none", md: "flex"},
                  justifyContent: 'center'
              }}
          >
            <Box 
              alignSelf={"center"}
              component="img"
              src={nextiles}
              alt="Nextiles Logo"
              />
        </Grid>

        <Grid item xs={20} sm={20} md={9} component={Paper} elevation={6}>

          <Outlet />

        </Grid>
        
      </Grid> 
    </StyledRoot>
  );
}
