// @mui
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// ----------------------------------------------------------------------
const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  p: 1,
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(12),
  height: theme.spacing(12),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));
// ----------------------------------------------------------------------

export default function StatsCard ({ title, total, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 2.5,
        borderRadius: 0,
        // boxShadow: 0,
        textAlign: 'center',
        height: "30vh",
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => `${theme.palette[color].light}1A`,
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].main,
          backgroundColor: (theme) => `${theme.palette[color].light}6A`,
          // backgroundImage: (theme) =>
          //   `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
          //     theme.palette[color].dark,
          //     0.24
          //   )} 100%)`,
          borderColor: (theme) => theme.palette[color].main,
          border: '1px solid',
        }}
      >
        <Typography variant="h3">{total}</Typography>
      </StyledIcon>

      <Typography variant="h6" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
