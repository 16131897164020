// ----------------------------------------------------------------------

export function remToPx(value) {
    return Math.round(parseFloat(value) * 16);
  }
  
export function pxToRem(value) {
return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
return {
    '@media (min-width:600px)': {
    fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
    fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
    fontSize: pxToRem(lg),
    },
};
}

// ----------------------------------------------------------------------
  
  const FONT_PRIMARY = ["Greycliff CF", "Nasalization"].join(","); 

  const typography = {
    fontFamily: FONT_PRIMARY,
    fontSize: 12,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 400,
      fontFamily: "Nasalization",
      lineHeight: pxToRem(112),
      fontSize: pxToRem(96),
      ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    },
    h2: {
      fontFamily: "Nasalization",
      fontWeight: 400,
      lineHeight: pxToRem(72),
      fontSize: pxToRem(60),
    },
    h3: {
      fontFamily: "Nasalization",
      fontWeight: 400,
      lineHeight: pxToRem(43),
      fontSize: pxToRem(35),
    },
    h4: {
      fontFamily: "Nasalization",
      fontWeight: 400,
      lineHeight: pxToRem(38),
      fontSize: pxToRem(28),
    },
    h5: {
      fontFamily: "Nasalization",
      fontWeight: 400,
      lineHeight: pxToRem(33),
      fontSize: pxToRem(24),
    },
    h6: {
      fontFamily: "Nasalization",
      fontWeight: 400,
      lineHeight: pxToRem(30),
      fontSize: pxToRem(18),
    },
    // subtitle1: {
    //   fontFamily: "Greycliff CF",
    //   fontWeight: 600,
    //   lineHeight: 1.5,
    //   fontSize: pxToRem(16),
    // },
    // subtitle2: {
    //   fontWeight: 600,
    //   lineHeight: 22 / 14,
    //   fontSize: pxToRem(14),
    // },
    // body1: {
    //   lineHeight: 1.5,
    //   fontSize: pxToRem(16),
    // },
    body2: {
      lineHeight: pxToRem(20),
      fontSize: pxToRem(14),
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
    },
    // overline: {
    //   fontWeight: 700,
    //   lineHeight: 1.5,
    //   fontSize: pxToRem(12),
    //   textTransform: 'uppercase',
    // },
  };
  
  export default typography;
  