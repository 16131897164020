import React, { useState, useEffect } from 'react';
import validator from "validator";
// @mui
import {
  Stack, 
  Modal, 
  Divider, 
  Typography, 
  IconButton,
  TextField,
  Card,
  CardHeader,
  Collapse,
} from '@mui/material/';
// // @mui-icons
import { Close } from '@mui/icons-material';
// APIs
import { inviteUserToOrganization, upgradeUserToAdminViaEmail, accountCheck } from '../../api/orgs';
import { registerInvite } from '../../api/users'
// Utils
import { commonModalStyle } from '../../utils/customStyles';
import { toISOLocal } from '../../utils/formatFunctions'
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function InviteEmail ({
  orgId,
  userType, 
  openModal,
  handleCloseModal,
  snackbarState,
  setSnackbarState
}){
 const [errorMessage, setErrorMessage] = useState("")
 const [email, setEmail] = useState("")
 const [color, setColor] = useState("")
 const [disable, setDisable] = useState(true)
 const [loading, setLoading] = useState(false)
 const [first, setFirst] = useState("")
 const [last, setLast] = useState("")
 const [emailHelperText, setEmailHelperText]= useState("")
 const [inviteNewAthlete, setInviteNewAthlete] = useState(false)
 const [newUser, setNewUser] = useState(false)

 useEffect(() => {
  const delayDebounceFn = setTimeout(async () => {
    const isValidEmail = validator.isEmail(email);
    if (isValidEmail) {
      const response = await accountCheck(orgId, email.toLowerCase());
      const { data, statusCode } = response

      if(statusCode === 200) {
        //if user doesnt exist
        if(!data.data.existing_user) {
          setInviteNewAthlete(true)
          setDisable(true)
          setNewUser(true)
          setEmailHelperText("")
          setColor("")
          // Set state here to track that its a non existing user 
        } else if(data.data.in_org) {
          // if user exists and is in org
          setInviteNewAthlete(false)
          setEmailHelperText(userType === "Admin"? "User exists already! Please upgrade them from the user list." : "User account already exists in this organization!")
          setColor("error")
        } else {
          // if user profile exists but isnt in current org
          setInviteNewAthlete(false)
          setEmailHelperText("User exists. Feel free to invite them to this organization!")
          setDisable(false)
          setColor("success")
        }
      }
    } else {
      setInviteNewAthlete(false)
    }
  }, 600)

  return () => clearTimeout(delayDebounceFn)
}, [email])

useEffect(()=> {
  setEmail("")
  setFirst("")
  setLast("")
  setErrorMessage("")
  setEmailHelperText("")
  setLoading(false)
  setDisable(true)
  setColor("")
},[userType])


 const handleEmailChange = (e) => {
  setDisable(true)
  setColor("")
  setEmailHelperText("")
  setInviteNewAthlete(false)
  const sanitizedValue = e.target.value.replace(/\s/g, '');
  const email = e.target.value
  setEmail(email)
}

const isEmailValid = (email) => {
  return validator.isEmail(email);
}

const handleInvite = async() => {
  const isoDate = toISOLocal(new Date());
  setLoading(true)

  if (isEmailValid(email) ) {
    if(userType === "User" && !newUser) {
      const response = await inviteUserToOrganization(email.toLowerCase(), orgId)
      const { data, status } = response
    
      if(status === 200) {
        setSnackbarState({ 
          ...snackbarState, 
          open: true, 
          text: data.data.message 
        });
        handleCloseModal()
      } else {
        setErrorMessage(data.data.message)
      }
    } else if ( userType === "Admin" && !newUser) {
      const response = await inviteUserToOrganization(email.toLowerCase(), orgId)
      const { data, status } = response
    
      if(status === 200) {
        const adminResponse = await upgradeUserToAdminViaEmail(email.toLowerCase(), orgId)
        console.log("Upgrade user to admin via email", adminResponse)

        if (adminResponse.status === 200){
          setSnackbarState({ 
            ...snackbarState, 
            open: true, 
            text: adminResponse.data.data.message 
          });
          handleCloseModal()
        } else {
          setErrorMessage(adminResponse.data.data.message)
        }
      } else {
        setErrorMessage(data.data.message)
      }
    } else {
      // Register New User
      const userData = {
        first_name: first,
        last_name: last,
        email: email.toLowerCase(),
        date: isoDate,
        user_metadata: {
          height: null,
          weight: null,
          dob: null,
          dominant_arm: null,
          dominant_leg: null,
          country: null,
          gender: null,
        },
        org_id: orgId
      };

      const response = await registerInvite(userData)
      const { data, statusCode } = response
      console.log(response)
      if (statusCode === 200) {
        if(userType === "Admin"){
          const adminResponse = await upgradeUserToAdminViaEmail(email.toLowerCase(), orgId)

          if (adminResponse.status === 200){
            setSnackbarState({ 
              ...snackbarState, 
              open: true, 
              text: adminResponse.data.data.message 
            });
            handleCloseModal()
          } else {
            setErrorMessage(adminResponse.data.data.message)
          }
        } else {
          setSnackbarState({ 
            ...snackbarState, 
            open: true, 
            text: response.data.data.message 
          });
          handleCloseModal()
        }
      } else {
        setErrorMessage(data.data.message)
      }

    }
  } else {
    setErrorMessage("Please enter a valid email.")
  }
  setLoading(false)
}

  return (
    <Modal
      open={openModal}
      hideBackdrop={false}
      onClose={handleCloseModal}
    >
      <Card sx={commonModalStyle}>
        <CardHeader 
          title={`Invite ${userType}`}
          action={
            <IconButton onClick={handleCloseModal}>
              <Close/>
            </IconButton>
          }
        />

        <Divider/>

        <Stack spacing={2} mt={2}>

          <TextField 
            label="Email" 
            helperText={<Typography variant='subtitle1'>{emailHelperText}</Typography>}
            fullWidth
            color={color}
            value={email} 
            onChange={handleEmailChange}
          />

          <Collapse in={inviteNewAthlete}>
            <Stack spacing={1}>

              <TextField
                type='text'
                error={false}
                color={color}
                required
                fullWidth
                name='first'
                value={first}
                variant="outlined"
                label="First Name"
                onChange={(e) => {

                  const trimmedValue = e.target.value.trim();
              
                  // Check for minimum length
                  if (trimmedValue.length < 2) {
                    setFirst(trimmedValue);
                    setErrorMessage("Please enter a minimum of two characters.")
                  } else {
                    setErrorMessage("");
                    setFirst(trimmedValue)
                  }
              
                  // Check both first and last names for minimum length
                  const isFirstNameValid = trimmedValue.length >= 2;
                  const isLastNameValid = last.trim().length >= 2;
              
                  // Update disable state based on both first and last names
                  setDisable(!(isFirstNameValid && isLastNameValid));
              }}
              />

              <TextField
                type='text'
                mt={2}
                required
                color={color}
                name='last'
                fullWidth
                value={last}
                variant="outlined"
                label="Last Name"
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim();
              
                  // Check for minimum length
                  if (trimmedValue.length < 2) {
                    setErrorMessage("Please enter a minimum of two characters.")
                    setLast(trimmedValue);
                  } else {
                    setErrorMessage("")
                    setLast(trimmedValue);
                  }
                  // Check both first and second names for minimum length
                  const isFirstNameValid = first.trim().length >= 2;
                  const isSecondNameValid = trimmedValue.length >= 2;
              
                  // Update disable state based on both first and second names
                  setDisable(!(isFirstNameValid && isSecondNameValid));
              }}
              />
            </Stack>

          </Collapse>

          <Typography sx={{color:"error.main"}}>{errorMessage}</Typography>

          <LoadingButton
            loading={loading}
            disabled={disable}
            variant="contained"
            onClick={()=> handleInvite()}
            sx={{width: "50%", alignSelf: 'center'}}
          >
              {`Invite ${userType}`}
          </LoadingButton>

        </Stack>
      </Card>
    </Modal>
  )
}