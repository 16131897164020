// @mui
import { 
  Typography, Table, TableCell, TableRow, TableContainer, TableBody, Chip, Stack
} from '@mui/material';
// Components
import { OrgListHead } from '../orgs';
// Utils
import { productIconMap } from '../../utils/mapFunctions';
import { CustomTooltip } from '../../utils/customStyles';
// ----------------------------------------------------------------------

export default function OrgMetricList ({ list, activityData, }) {

  const tableHead = [
    { id: 'activity', label: 'Activity', alignRight: false },
    { id: 'product', label: 'Product', alignRight: false },
    { id: 'metric', label: 'Metric', alignRight: false },
  ];

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Table>
        <OrgListHead
          // order={order}
          // orderBy={orderBy}
          headLabel={tableHead}
          // onRequestSort={handleRequestSort}
        />
        <TableBody>
          {list.map((row) => {
            return <OrgMetric metric={row} activityData={activityData}/>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// ----------------------------------------------------------------------

function OrgMetric ({ metric, activityData }) {
  const { display_name, products, activity_id } = metric;

  const findKeyByActivityId = (product_ids, activityId) => {
    for (const key in product_ids) {
        if (product_ids[key].includes(activityId)) {
            return key;
        }
    }
    return null; 
  };

  const product = findKeyByActivityId(products, activity_id)
  
  let metrics = null
  if(activityData["activity_metric_mapping"]){
    metrics = activityData["activity_metric_mapping"][activity_id]
    if(metrics.length === 0) metrics = null
  }

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" scope="row">
        <CustomTooltip 
          title={
            <Typography variant='caption'>
              {activity_id}
           </Typography> 
          }>
          <Typography color="inherit" fontWeight={700} underline="hover" noWrap>
            {display_name}
          </Typography>
        </CustomTooltip>
      </TableCell>

      <TableCell>
        <CustomTooltip 
          title={
           <Typography variant='caption'>
            {product}
           </Typography> 
          }
        >
          {productIconMap[product]}
        </CustomTooltip>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1}>
          { metrics ? metrics.map((metric)=> (
            <Chip label={metric} variant="outlined"/>
          )) 
        :
        <Typography>There are no metrics to show.</Typography>
        }
        </Stack>
      </TableCell>
    </TableRow>
  );
}
