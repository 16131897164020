export const handleCopyClick = (id) => {
  navigator.clipboard.writeText(id)
    .then(() => {
      console.log(id)
    })
    .catch((err) => {
      console.error("Unable to copy text: ", err);
    });
};

