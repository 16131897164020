import  React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Grid, Container, Typography, Stack, Avatar, Divider, Button, Accordion, AccordionSummary, AccordionDetails, Snackbar, Alert, AccordionActions, IconButton } from '@mui/material';
// icons
import { AssignmentIndOutlined, Add, ContentCopy, InfoOutlined, WarningOutlined, ExpandMore, DataObject } from '@mui/icons-material';
// Sections
import { 
    OrgUserList, OrgMetricList, EditOrg, OrgApiTokenList,
    CreateOrgApiToken, EditApiToken, InviteEmail,
} from '../sections/org'
// API Calls
import { getOrgInfoAndUsers } from "../api/orgs";
// Utils
import { formatDate, calculateExpiration, isValidDateFormat } from "../utils/formatFunctions";
import { handleCopyClick } from "../utils/copyFunction";
import { CustomTooltip } from "../utils/customStyles";
import { handleShowJson } from "../utils/jsonFunction";
import { findSessionByOrgId } from "../api/sessions";
// ----------------------------------------------------------------------

export default function Org () {
  const location = useLocation();
  const [orgData, setOrgData] = useState(null)
  const [orgUsers, setOrgUsers] = useState(null)
  const [menuData, setMenuData] = useState(null)
  const [orgSessions, setOrgSessions] = useState(null)
  const [orgAdmins, setOrgAdmins] = useState(null)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openApiModal, setOpenApiModal] = useState(false)
  const [openEditApiModal, setOpenEditApiModal] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [activityData, setActivityData] = useState([])
  const [userType, setUserType] = useState("")
  const [currentToken, setCurrentToken] = useState("")
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    text: ""
  });
  const { vertical, horizontal, open, text } = snackbarState;

  const orgId = location.state.orgId || location.state.state.orgId
  const orgMenus = location.state.orgMenus || location.state.state.orgMenus

  useEffect(()=> {

    const fetchOrgData = async(id) => {
        try {
            const orgData = await getOrgInfoAndUsers(id)
            const orgSessions = await findSessionByOrgId(id)

            const filteredSessions = orgSessions.data.filter(org => org.org_id === id);
            const users = orgData.users

            if(orgData.org[0].menu_id){
                const menu = orgMenus.find(menu => menu.pk_id === orgData.org[0].menu_id)
                setMenuData(menu)
            }

            setOrgData(orgData.org[0])
            setActivityData(orgData.org[1])
            setOrgUsers(users)
            setOrgSessions(filteredSessions)
        } catch(err) {
            console.log(err)
        }
    }

    fetchOrgData(orgId)
  }, [orgId, orgAdmins, snackbarState, openEditApiModal, openApiModal, openInviteModal])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleOpenInviteModal = (type) => {
    setOpenInviteModal(true)
    setUserType(type)
  }

  const openEditAPIModal = (token) => {
    setOpenEditApiModal(true);
    setCurrentToken(token)
  };

  if (orgData && orgUsers) {
    const remainingMembershipTime = calculateExpiration(orgData.date_created, orgData.date_duration);
    const dateIsValid = isValidDateFormat(orgData.date_created)
    const users = orgUsers.filter(user => user.type === "user")
    const admins = orgUsers.filter(user => user.type === "admin")
    const displayJsonMetrics = menuData ? menuData : activityData

    return (
      <Container maxWidth="xl">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack 
                    direction="row" 
                    justifyContent="space-between" 
                    alignItems={"center"} 
                    mb={2}
                >
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Avatar alt={orgData.name} src={orgData.s3_logo_url}/>
                        
                        <Typography variant="h3">{orgData.name}</Typography>

                        {orgData.notes && 
                            <CustomTooltip 
                                title={
                                    <Typography varient="caption">
                                        {orgData.notes}
                                    </Typography>
                                }
                            >
                                <InfoOutlined/>
                            </CustomTooltip>
                        }

                        {dateIsValid === false &&                  
                            <CustomTooltip 
                                title={
                                    <Typography variant="caption">
                                        Invalid Date Format
                                    </Typography>
                                } 
                            >
                                <WarningOutlined sx={{color: 'error.main'}}/>
                            </CustomTooltip>
                        }

                        <IconButton onClick={() => handleShowJson(orgData, window)}>
                            <DataObject/>
                        </IconButton>

                    </Stack>

                    <Button variant="contained" disableRipple onClick={()=> setOpenEditModal(true)}>
                        Edit Organization
                    </Button>

                    <EditOrg 
                        openModal={openEditModal} 
                        handleCloseModal={()=> setOpenEditModal(false)} 
                        orgId={orgId} 
                        orgMenus={orgMenus}
                        orgData={orgData}
                        snackbarState={snackbarState} 
                        setSnackbarState={setSnackbarState}
                    />
                </Stack>

                <Stack         
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    mb={2}
                >
                    <Stack direction="row" spacing={1}>
                        <CustomTooltip 
                            title={
                                <>
                                    <Typography variant="caption">
                                        Copy Org ID
                                    </Typography>
                                    <IconButton onClick={()=> handleCopyClick(orgId)}>
                                        <ContentCopy />
                                    </IconButton>
                                </>
                            }
                        >
                            <AssignmentIndOutlined />
                        </CustomTooltip>
                        <Typography fontWeight={700} noWrap>Org ID</Typography>
                    </Stack>

    
                    <Typography><b>Created On:</b> &nbsp;{formatDate(orgData.date_created.slice(0,10))}</Typography>
        
                    <Typography><b>User Count:</b> &nbsp;{orgUsers && orgUsers.length}</Typography>
                
                    <Typography><b>{remainingMembershipTime.differenceInDays <= 0 ? "Expired on:": "Expires on:"}</b> &nbsp;{formatDate(remainingMembershipTime.expirationDate)}</Typography>

                    <Typography><b>User Max:</b> &nbsp;{orgData.user_max}</Typography>

                    <Typography><b>Admin Max:</b> &nbsp;{orgData.admin_max}</Typography>
    
                    { orgData.menu_id ? 
                        <Typography><b>Menu ID:</b> &nbsp;{orgData.menu_id}</Typography>
                    :
                        <Typography><b>Menu ID:</b> &nbsp; N/A</Typography>
                    }
                    { orgData.type && 
                        <Typography><b>Org Type:</b> &nbsp;{orgData.type}</Typography>
                    }
                </Stack>

                <Divider/>
            </Grid>
        
            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Typography variant="h5">
                            API List
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <OrgApiTokenList
                            openModal={openEditAPIModal} 
                            list={
                                orgData.token_api?.map((token) => ({
                                    expired: token.expired,
                                    dateCreated: token.date_created,
                                    token: token.token,
                                    dateDuration: token.date_duration
                                })) ?? []
                            }
                        />
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            startIcon={<Add sx={{color: "primary.main"}}/>}
                            onClick={()=> setOpenApiModal(true)} 
                        >
                            Add API Token
                        </Button>
                    </AccordionActions>
                </Accordion>

                <CreateOrgApiToken 
                    openModal={openApiModal} 
                    handleCloseModal={()=> setOpenApiModal(false)} 
                    orgName={orgData.name} 
                    orgId={orgId}
                />

                <EditApiToken 
                    orgId={orgId}
                    openModal={openEditApiModal} 
                    currentToken={currentToken}
                    handleCloseModal={()=> setOpenEditApiModal(false)}
                />
            </Grid>

            {displayJsonMetrics && 
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <Typography variant="h5">Metric List</Typography>
                                <IconButton onClick={() => handleShowJson(displayJsonMetrics, window)}>
                                    <DataObject/>
                                </IconButton>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrgMetricList
                                activityData={displayJsonMetrics}
                                list={displayJsonMetrics.activity_list.map((metric, index) => ({
                                    display_name: metric.display_name,
                                    products: displayJsonMetrics.product_activity_mapping,
                                    metric_list: displayJsonMetrics.activity_metric_mapping,
                                    activity_id: metric.activity_id,
                                }))}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }

            {orgSessions && 
                <>
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                <Typography variant="h5">Admin List</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <OrgUserList
                                    title="Admin List"
                                    setOrgAdmins={setOrgAdmins}
                                    orgSessions={orgSessions}
                                    action={
                                        <Button 
                                            disableRipple 
                                            disableElevation
                                            startIcon={<Add sx={{color: "primary.main"}}/>}
                                            onClick={()=> handleOpenInviteModal("Admin")} 
                                        >
                                            Add Admin
                                        </Button>
                                    }
                                    list={admins.map((admin, index) => ({
                                            id: index,
                                            name: admin.name,
                                            userId: admin.user_id,
                                            orgId: admin.organization_id,
                                            adminNotes: admin.admin_settings.notes,
                                            dateCreated: admin.date_created,
                                            email: admin.email,
                                            totalSessions: admin.session_history_metadata.total_sessions,
                                            userType: admin.type,
                                            isActive: admin.is_active,
                                            dateDuration: admin.date_duration,
                                            usersData: admin
                                        }))
                                    }
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                <Typography variant="h5">User List</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <OrgUserList
                                    title="User List"
                                    setOrgAdmins={setOrgAdmins}
                                    orgSessions={orgSessions}
                                    action={
                                        <Button 
                                            disableRipple 
                                            disableElevation
                                            startIcon={<Add sx={{color: "primary.main"}}/>}
                                            onClick={()=>handleOpenInviteModal("User")} 
                                        >
                                            Add User
                                        </Button>
                                    }
                                    list={users.map((user, index) => ({
                                        id: index,
                                        name: user.name,
                                        userId: user.user_id,
                                        orgId: user.organization_id,
                                        adminNotes: user.admin_settings.notes,
                                        dateCreated: user.date_created,
                                        dateDuration: user.date_duration,
                                        email: user.email,
                                        totalSessions: user.session_history_metadata.total_sessions,
                                        userType: user.type,
                                        isActive: user.is_active,
                                        usersData: user
                                    }))}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <InviteEmail 
                            openModal={openInviteModal} 
                            userType={userType}
                            handleCloseModal={()=> setOpenInviteModal(false)} 
                            orgId={orgId} 
                            snackbarState={snackbarState} 
                            setSnackbarState={setSnackbarState}
                        />

                    </Grid>
                </>
            }
            {/* Pull Out */}
            <Snackbar 
                open={open} 
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}
                key={vertical + horizontal}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {text}
                </Alert>
            </Snackbar>
        </Grid>
      </Container>
    );
  }
}