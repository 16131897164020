// List all users in an organization
export const listAllOrgUsers = async () => {
    try {
      const response = await fetch(`/data/users`);
      const data = await response.json();
      return data
    } catch (error) {
      console.error('Error fetching org users:', error);
    }
  }

// Find User by Id
export const findUserById = async (userId, orgId) => {
  try {
    const response = await fetch(`/data/user/${userId}/${orgId}`);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Find User Orgs by Id
export const findUserOrgsById = async (userId) => {
  try {
    const response = await fetch(`/data/userorgs/${userId}`);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}
// Find User by Id
export const getUserMetadata = async (userId) => {
  try {
    const response = await fetch(`/data/usermetadata/${userId}`);
    const data = await response.json();
    return  data ; 
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Find User Main DDB entry by Id
export const getUserDDBEntry = async (userId) => {
  try {
    const response = await fetch(`/data/userddbentry/${userId}`);
    const data = await response.json();
    return  data ; 
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Update User Metadata
export const updateUserMetadata = async (userData) => {
  try {
    const response = await fetch(`/data/updateusermetadata`, {
        method: 'PUT',
        body: JSON.stringify({userData}),
        headers: {
          'Content-Type': 'application/json',
        }
    });

    const data = await response.json();
    return  data ; 
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Update User Org Profile
export const updateUserOrgProfile = async (userData) => {
  try {
    const response = await fetch(`/data/updateuserorgprofile`, {
        method: 'PUT',
        body: JSON.stringify({userData}),
        headers: {
          'Content-Type': 'application/json',
        }
    });

    const data = await response.json();
    return  data ; 
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Put User Org Date Duration in User Org Profile
export const putUserOrgDateDuration = async (userData) => {
  try {
    const response = await fetch(`/data/putuserorgdateduration`, {
      method: 'PUT',
      body: JSON.stringify({userData}),
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = await response.json();
    return  data ; 
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Put User Org Date Duration in User Org Profile
export const updateExpiredToken = async (userData) => {
  try {
    const response = await fetch(`/data/updateuserorgexpiration`, {
        method: 'PUT',
        body: JSON.stringify({ userData }),
        headers: {
          'Content-Type': 'application/json',
        }
    });

    const data = await response.json();
    return  data ; 
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Demote admin to User
export const demoteAdminToUser = async (userId, orgId) => {
  try {
    const response = await fetch(`/data/demote/${userId}/${orgId}`);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Upgrade user to admin
export const upgradeUserViaDdb = async (userId, orgId) => {
  try {
    const response = await fetch(`/data/upgradeuser/${userId}/${orgId}`);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching user:', error);
  }
}

// Upgrade User to Admin 
export const upgradeUserToAdmin = async (userId, orgId) => {
  try {
    const response = await fetch(`/data/upgrade/${userId}/${orgId}`);
    const data = await response.json();
    console.log("Org Users:", data)
    return data
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}

// Register Invited User
export const registerInvite = async (userData) => {
  try {
    const response = await fetch('/data/registerinvite', {
      method: 'POST',
      body: JSON.stringify({userData}),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    const statusCode = response.status; 

    return { data, statusCode }; 
  } catch (error) {
    console.error('Error fetching data:', error);
    return { error };
  }
};